import React, { useState, useEffect } from "react";
import BioDetails from "../../ReuseComponents/Bio/BioDetails";
import {
  Grid,
  Box,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  MenuItem,
  // Select,
  FormGroup,
  Checkbox,
  Card,
  TextField,
  Typography,
  Button,
  IconButton,
  InputAdornment,
  FormHelperText,
  Tooltip,
} from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/en";
import "dayjs/plugin/duration";
import "dayjs/plugin/utc";
import Modal from "@mui/material/Modal";
import Select from '@mui/material/Select';

import "./setting.css";
import BreadCrumbs from "../../ReuseComponents/BreadCrumbs/BreadCrumbs";
import Save from "../../../Assets/icons/addUserTick.svg";
import editIcon from "../../../Assets/icons/editIcon.svg";
import cancelIcon from "../../../Assets/icons/cancelIcon.svg";

// --------- custom icons ------------
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";
import Controller from "../../../Controller/ApiController";
import { Alert } from "../../ReuseComponents/Alert/Alertmessage";
import {
  DatePicker,
  DesktopDatePicker,
  DesktopTimePicker,
  TimePicker,
} from "@mui/x-date-pickers";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import ConfirmationPop from "../../ReuseComponents/Popup/ConfirmationPop";
import Header from "../../ReuseComponents/Header/Header";
import { updateSetting } from "../../Assets/Redux/features/updateRequest/updateRequest";
import { useDispatch, useSelector } from "react-redux";

const isoDateTimeString1 = "2024-01-29T12:34:56.789Z";
const isoDateTimeString2 = "2024-02-29T12:34:56.789Z";

export default function SettingPage() {
  const [editMode, setEditMode] = useState(false);
  const [initialData, setInitialData] = useState(null);
  const [selectedRadioValue, setSelectedRadioValue] = useState("");
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [selectedDays, setSelectedDays] = useState([]);
  const [morningChecked, setMorningChecked] = useState(false);
  const [morningStartTime, setMorningStartTime] = useState('');
  const [morningEndTime, setMorningEndTime] = useState('');
  const [width, setWidth] = useState("");
  const [height, setHeight] = useState("");

  //   useEffect(()=>{
  // console.log("morningEndTime :" ,morningEndTime)
  //   },[])
  const [eveningChecked, setEveningChecked] = useState(false);
  const [eveningStartTime, setEveningStartTime] = useState('');
  const [eveningEndTime, setEveningEndTime] = useState('');
  const [selectedSlotDuration, setSelectedSlotDuration] = useState("10:00");
  const [morningSlots, setMorningSlots] = useState("");
  const [eveningSlots, setEveningSlots] = useState("");
  const [selectedTokenOption, setSelectedTokenOption] = useState("SEQUENTIAL");
  const [prefixSelection, setPrefixSelection] = useState("NO");
  const [prefixValue, setPrefixValue] = useState("");
  const [ivrConfigDay, setIvrConfigDay] = useState("1");
  const [ivrConfigSlot, setIvrConfigSlot] = useState("1");
  const [EmergencyDelay, setEmergencyDelay] = useState("");
  const [GeneralDelay, setGeneralDelay] = useState("");
  const [validationError, setValidationError] = useState("");
  const [startTimeError, setStartTimeError] = useState(null);
  const [endTimeError, setEndTimeError] = useState(null);
  const [prefixError, setPrefixError] = useState("");
  const [dataNotFound, setDataNotFound] = useState("");
  const [settingDataLength, setSettingDataLength] = useState(0);
  const [EmergencyNotApplicable, setEmergencyNotApplicable] = useState(false);
  const [GeneralNotApplicable, setGeneralNotApplicable] = useState(false);
  const [cancelOpen, setCancelOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [ errorMessage, setErrorMessage ] = useState(null);
  const [fromDateError, setFromDateError] = useState(null);
  const [toDateError, setToDateError] = useState(null);
  const [ dayTimeError, setDayTimeError ] = useState(null);
  const [isDayCheckboxSelected, setIsDayCheckboxSelected] = useState(false);
  const [defaultFromDate, setDefaultFromDate] = useState(null);
  const [defaultToDate, setDefaultToDate] = useState(null);
  const [ availabilityDaysError, setAvailabilityDaysError] = useState(null);
  const [appointmentAvailable, setAppointmentAvailable] = useState("");
  const [settingAvailable, setSettingAvailable] = useState("");
  const [appointmentUID, setAppointmentUID] = useState("");
  const [ slotError, setSlotError ] = useState("");
  const [currentSetting, setCurrentSetting] = useState(false);
  const updateSettingValue = useSelector( (state) => state.updateRequest.update_setting);
  const [availabilityDays, setAvailableDays] = useState([]);
  const dispatch = useDispatch();
  const [popupData, setPopUpData] = useState({
    Purpose: "",
    message: "",
    DataDetails: "",
  });

  useEffect(()=>{
    setWidth(window.innerWidth)
    setHeight(window.innerHeight)
  })

  const yesSubmit = (Data) => {
    if (Data.Purpose == "Cancel") {
      setEditMode(false);
      setCancelOpen(false);
      FetchData(selectedRadioValue, "");
      setFromDateError(null)
      setToDateError(null)
    }
    console.log(Data, "ff");
    if (Data.Purpose == "Confirmation") {
      handleEditClick(true);
    }
    setOpen(false);
  };

  const openPopup = (PopupType, Data) => {
    if (PopupType == "Cancel") {
      setPopUpData({
        ...popupData,
        Purpose: "Cancel",
        message: "Are you sure you want to cancel this activity?",
      });
    }
    if (PopupType == "Confirmation") {
      setPopUpData({
        ...popupData,
        Purpose: "Confirmation",
        message: "Another configuration setup is already available. Do you want to view and edit?",
      });
    }
    setOpen(true);
  };


  let   Doctor_id = localStorage.getItem("DoctorUid");

  const handleEditClick = (event) => {
    setEditMode(true);
  };

  const handleRadioClick = async (event) => {   
    if (editMode) {
      return;
    }
    const value = event.target.value;
    setSelectedRadioValue(value);
    FetchData(value, "");
  };

  function getDaysInArrayOfStrings(start, end) {
    const days = [];
    let currentDate = start.clone();
    let dayCount = 0;
    //Finding the days between given dates
    while ((currentDate.isBefore(end) || currentDate.isSame(end)) && dayCount <= 7 ) {
        days.push(currentDate.format('dddd').toLowerCase());
        dayCount++;
        currentDate = currentDate.add(1, 'day');
    }    

    const uniqueDays = days.filter((item, i) => days.indexOf(item) === i);
    setAvailableDays(uniqueDays);
    setSelectedDays(uniqueDays);
}

  const handleFromDateChange = (date) => {
    setFromDate(date);
    if(toDate != null){
      getDaysInArrayOfStrings(date,toDate)
    }
    setFromDateError(null);
  };

  const handleToDateChange = (date) => {
    setToDate(date);
    if(fromDate != null){
      getDaysInArrayOfStrings(fromDate,date)
    }
    setToDateError(null);
  };

  const [loaderopen, setloaderOpen] = React.useState(false);

  const handleLoaderClose = () => {
    setloaderOpen(false);
  };
  const handleLoaderOpen = () => {
    setloaderOpen(true);
  };
  const parseTimeString = (timeString) => {
    const [hours, minutes] = timeString.split(":").map(Number);
    return { hours, minutes };
  };

  const FetchData = async (type, action) => {
    handleLoaderOpen();
    try {
      const filterType = { "filter.type": type,"filter.action": action };
      const url = `/setting/${Doctor_id}`;
      let res = await Controller.ApiController("GET", url, "", filterType);
      handleLoaderClose();
      console.log(res, "dsds");
      if (res.type === "success") {
        const getData = res.data;
        console.log(getData,"getData")
        setAppointmentAvailable(res.appointment_available);
        console.log(res.setting_available, "ava")
        if (res.appointment_available == true) {
          openPopup("Confirmation") 
        } 
        else if(action == "EDIT" || action == "UPDATE"){
          handleEditClick()
        } 
        setSettingAvailable(true);
        setAppointmentUID(getData.availability_data.availability_uid);
        setSettingDataLength(Object.keys(getData).length);
        setCurrentSetting(getData.availability_data.status == "CURRENT_SETTING")
        if (
          getData.availability_data.morning_duration_from ||
          getData.availability_data.morning_duration_to
        ) {
          setMorningChecked(true);
        } else {
          setMorningChecked(false);
        }

        // Check if evening values are present and enable the evening checkbox
        console.log( getData.availability_data.evening_duration_from ||
          getData.availability_data.evening_duration_to,"getData");
        if (
          getData.availability_data.evening_duration_from ||
          getData.availability_data.evening_duration_to
        ) {
          setEveningChecked(true);
        } else {
          setEveningChecked(false);
        }
        const formattedMorningStartTime = getData.availability_data
          .morning_duration_from
          ? dayjs(getData.availability_data.morning_duration_from, "HH:mm")
          : null;
        const formattedMorningEndTime = getData.availability_data
          .morning_duration_to
          ? dayjs(getData.availability_data.morning_duration_to, "HH:mm")
          : null;
        const formattedEveningStartTime = getData.availability_data
          .evening_duration_from
          ? dayjs(getData.availability_data.evening_duration_from, "HH:mm")
          : null;
        const formattedEveningEndTime = getData.availability_data
          .evening_duration_to
          ? dayjs(getData.availability_data.evening_duration_to, "HH:mm")
          : null;
        const formattedFromDate = getData.availability_data
          .specific_setting_from
          ? dayjs(getData.availability_data.specific_setting_from, "YYYY-MM-DD")
          : null;
        const formattedToDate = getData.availability_data.specific_setting_to
          ? dayjs(getData.availability_data.specific_setting_to, "YYYY-MM-DD")
          : null;
        // console.log(getData);
        if(getData.availability_data.type == "SPECIFIC_SETTING" && formattedFromDate != null && formattedToDate != null) {
          getDaysInArrayOfStrings(dayjs(formattedFromDate), dayjs(formattedToDate))
        }
        setSelectedDays(getData.availability_data.availability_days);
        // setMorningChecked();
        setMorningStartTime(formattedMorningStartTime);
        setMorningEndTime(formattedMorningEndTime);
        // setEveningChecked();
        setEveningStartTime(formattedEveningStartTime);
        setEveningEndTime(formattedEveningEndTime);
        setSelectedSlotDuration(
          String(getData.availability_data.slot_duration)
        );
        setMorningSlots(getData.availability_data.morning_slots);
        setEveningSlots(getData.availability_data.evening_slots);
        setSelectedTokenOption(getData.token_data.type);
        setFromDate(formattedFromDate);
        setDefaultFromDate(formattedFromDate);
        setToDate(formattedToDate);
        setDefaultToDate(formattedToDate);
        setPrefixSelection(getData.token_data.prefix != "" ? "YES" : "NO");
        setPrefixValue(getData.token_data.prefix);
        setIvrConfigDay(getData.ivr_data.no_of_days);
        setIvrConfigSlot(getData.ivr_data.no_of_slots);
        setGeneralDelay(
          String(getData?.notification_data.general_delay_duration)
        );
        setEmergencyDelay(
          String(getData?.notification_data.emergency_delay_duration)
        );
        setSelectedRadioValue(getData.availability_data.type);
        console.log(getData, "getData");
      } else if (res.status === "FAILED") {
        // console.log(res)
        setAppointmentAvailable(res.error.appointment_available);
        if (res.error.appointment_available == true && res.error.setting_available == true) {
         openPopup("Confirmation") 
       }
        else if(action == "EDIT" || action == "UPDATE") {
          handleEditClick()
        } 
        setAppointmentUID("");
        setSettingAvailable(res.error.setting_available);
        setCurrentSetting(false);
        if (res.status_code == 404 && action != "EDIT") {
          // Alert("warning", "Please update your settings");
              setAvailableDays([]);
              setSelectedDays([]);
              setMorningChecked(false);
              setMorningStartTime(null);
              setMorningEndTime(null);
              setEveningChecked(false);
              setEveningStartTime(null);
              setEveningEndTime(null);
              setSelectedSlotDuration('');
              setMorningSlots('');
              setEveningSlots('');
              setSelectedTokenOption('SEQUENTIAL');
              setFromDate(null);
              setToDate(null);
              setDefaultFromDate(null);
              setDefaultToDate(null);
              setPrefixSelection('NO');
              setPrefixValue('');
              setIvrConfigDay('1');
              setIvrConfigSlot('1');
              setGeneralDelay('');
              setSettingDataLength(0);
              setEmergencyDelay('');
              setEmergencyNotApplicable(true);
              setGeneralNotApplicable(true);
        } else if (action != "EDIT") {
          Alert("error", res.error.message);
          setDayTimeError(res.error.message)
        }
        const DataNotFound = res.error.message;
        setDataNotFound(DataNotFound);
      }
    } catch (error) {
      handleLoaderClose();
      console.log(error);
    }
  };

  useEffect(() => {
    FetchData("", "");
  }, []);

  const handleCancelClick = () => {
    setEditMode(false);
    if (editMode && initialData) {
      setSelectedDays(initialData.selectedDays);
      setMorningChecked(initialData.morningChecked);
      setMorningStartTime(initialData.morningStartTime);
      setMorningEndTime(initialData.morningEndTime);
      setEveningChecked(initialData.eveningChecked);
      setEveningStartTime(initialData.eveningStartTime);
      setEveningEndTime(initialData.eveningEndTime);
      setSelectedSlotDuration(initialData.selectedSlotDuration);
      setMorningSlots(initialData.morningSlots);
      setEveningSlots(initialData.eveningSlots);
      setSelectedTokenOption(initialData.selectedTokenOption);
      setPrefixSelection(initialData.prefixSelection);
      setPrefixValue(initialData.prefixValue);
      setIvrConfigDay(initialData.ivrConfigDay);
      setIvrConfigSlot(initialData.ivrConfigSlot);
      setGeneralDelay(initialData.GeneralDelay);
      setEmergencyDelay(initialData.EmergencyDelay);
    }
    console.log(initialData.GeneralDelay, "helo");
  };

  useEffect(() => {
    const MorningSlots = calculateSlots(
      morningStartTime,
      morningEndTime,
      selectedSlotDuration
    );
    setMorningSlots(MorningSlots || '' || morningSlots);
    const EveningSlots = calculateSlots(
      eveningStartTime,
      eveningEndTime,
      selectedSlotDuration
    );
    setEveningSlots(EveningSlots || '' || eveningSlots);
  }, [
    morningStartTime,
    morningEndTime,
    eveningStartTime,
    eveningEndTime,
    selectedSlotDuration,
  ]);

  const handleSelectDayCheckbox = (event) => {
    const { value, checked } = event.target;
    setIsDayCheckboxSelected(checked);
    if (editMode) {
      setSelectedDays((prevSelectedDays) => {
        if (!Array.isArray(prevSelectedDays)) {
          setAvailabilityDaysError(null);
          return [value];
        }

        if (checked) {
          // Check if the value is already in the array to avoid duplicates
          if (!prevSelectedDays.includes(value)) {
            setAvailabilityDaysError(null);
            return [...prevSelectedDays, value];
          }
        } else {
          // Remove the value from the array
          const updatedSelectedDays = prevSelectedDays.filter((day) => day !== value);
          setAvailabilityDaysError(null);
          if(selectedDays?.length == 1) {            
          setMorningChecked(false);
          setEveningChecked(false);
          }
          return updatedSelectedDays;
          // return prevSelectedDays.filter((day) => day !== value);
        }

        // If no changes, return the unchanged array
        return prevSelectedDays;
        
      });
    }
  };

  const handleDurationCheckbox = (event) => {
    const { value, checked } = event.target;
    if (editMode) {
      if (value === "morning") {
        setMorningChecked(checked);
        if( !checked ){
          setMorningStartTime(null);
          setMorningEndTime(null);
        }else{
          setMorningStartTime(dayjs().set("hour", 8).set("minute", 0).set("second", 0));
          setMorningEndTime(dayjs().set("hour", 14).set("minute", 0).set("second", 0));
        }
        setDayTimeError(null);
      } else if (value === "evening") {
        setEveningChecked(checked);
        if( !checked ){
          setEveningStartTime(null);
          setEveningEndTime(null);
        }else{
          setEveningStartTime(dayjs().set("hours", 14).set("minutes", 0).set("seconds", 0).set("milliseconds", 0));
          setEveningEndTime(dayjs().set("hours", 23).set("minutes", 0).set("seconds", 0).set("milliseconds", 0));
        }
        setDayTimeError(null);
      }
    }
  };

  const calculateSlots = (
    newMorningStartTime,
    newMorningEndTime,
    selectedSlotDuration
  ) => {
    if (editMode) {
      if (!newMorningStartTime || !newMorningEndTime || !selectedSlotDuration) {
        return 0;
      }

      const startMoment = dayjs(newMorningStartTime) || 0;
      const endMoment = dayjs(newMorningEndTime) || 0;
      const durationInMinutes = endMoment.diff(startMoment, "minutes");
      const slotDuration = selectedSlotDuration != "0" ? selectedSlotDuration : 0;
      const slots = Math.floor(durationInMinutes / slotDuration);
      console.log(startMoment,endMoment,durationInMinutes,slots,slotDuration,selectedSlotDuration,"durationInMinutes")

      return slotDuration ? slots : 0;
    }
  };

  const handleStartTimeChange = (type, time) => {
    try {
      const isWithinRange =
        dayjs(time).isAfter(dayjs().set("hours", 8).set("minutes", 0)) &&
        dayjs(time).isBefore(dayjs().set("hours", 14).set("minutes", 0));

      const endTimeWithinRange =
        dayjs(morningEndTime).isAfter(
          dayjs().set("hours", 8).set("minute", 0)
        ) &&
        dayjs(morningEndTime).isBefore(
          dayjs().set("hours", 14).set("minute", 0)
        );
      const startTimeAfter = dayjs(morningStartTime).isAfter(
        dayjs(morningEndTime)
      );

      if (editMode) {
        if (!Array.isArray(time)) {
          time = [time, null];
        }

        const [startTime] = time;

        if (type === "morning" && morningChecked) {
          setMorningStartTime(startTime);
          if (startTimeAfter) {
            setStartTimeError("Start time should be lesser than end time");
          }
          // Check if start time is less than end time
          if (isWithinRange) {
            const newMorningStartTime = startTime;
            setMorningStartTime(newMorningStartTime);
            setStartTimeError(null);

            // Calculate and set morning slots
            const slots = calculateSlots(
              newMorningStartTime,
              morningEndTime,
              selectedSlotDuration
            );
            setMorningSlots(slots);
          }
        }

        if (type === "evening" && eveningChecked) {
          setEveningStartTime(startTime);
        }
      }
    } catch (e) {
      console.log("startTimeErr: ", e);
    }
  };
  const handleEndTimeChange = (type, time) => {
    const isWithinRange =
      dayjs(time).isAfter(dayjs().set("hours", 8).set("minutes", 0)) &&
      dayjs(time).isBefore(dayjs().set("hours", 14).set("minutes", 0));
    const startTimeWithinRange =
      dayjs(morningStartTime).isAfter(
        dayjs().set("hours", 8).set("minutes", 0)
      ) &&
      dayjs(morningStartTime).isBefore(
        dayjs().set("hours", 14).set("minutes", 0)
      );
    const endTimeBefore = dayjs(morningEndTime).isBefore(
      dayjs(morningStartTime)
    );

    if (editMode) {
      if (!Array.isArray(time)) {
        time = [time, null];
      }

      const [endTime] = time;
      if (type === "morning" && morningChecked) {
        setMorningEndTime(time);
        // Check if end time is greater than start time
        if (morningStartTime) {
          const newMorningEndTime = endTime;
          setMorningEndTime(newMorningEndTime);
          setEndTimeError(null);

          // Calculate and set morning slots
          const slots = calculateSlots(
            morningStartTime,
            newMorningEndTime,
            selectedSlotDuration
          );
          setEveningSlots(slots);
        }
      } else if (type === "evening" && eveningChecked) {
        setEveningEndTime(endTime);
        // Similar logic for evening
        // ...
      }
    }
  };

  useEffect(() => {
    const startTimeWithinRange =
      dayjs(morningStartTime).isAfter(
        dayjs().set("hour", 7).set("minute", 59).set("seconds", 59)
      ) &&
      dayjs(morningStartTime).isBefore(
        dayjs().set("hours", 14).set("minute", 1).set("seconds", 1)
      );

    const endTimeWithinRange =
      dayjs(morningEndTime).isAfter(
        dayjs().set("hour", 7).set("minute", 59).set("second", 59)
      ) &&
      dayjs(morningEndTime).isBefore(
        dayjs().set("hours", 14).set("minute", 1).set("seconds", 1)
      );

    const endTimeBefore = dayjs(morningEndTime).isBefore(
      dayjs(morningStartTime)
    );
    const startTimeAfter = dayjs(morningStartTime).isAfter(
      dayjs(morningEndTime)
    );
    if (
      morningChecked &&
      morningStartTime !== null &&
      morningEndTime !== null
    ) {
      if (endTimeWithinRange === false && startTimeWithinRange === true) {
        setStartTimeError("End time should be within the allowed range");
      } else if (
        startTimeWithinRange === false &&
        endTimeWithinRange === true
      ) {
        setStartTimeError("Start time should be within the allowed range");
      } else if (!endTimeWithinRange && !startTimeWithinRange) {
        setStartTimeError(
          "Start time and End time should be within the allowed range"
        );
      } else if (endTimeBefore) {
        setStartTimeError("End time should be greater than start time");
      } else if (startTimeAfter) {
        setStartTimeError("Start time should be lesser than end time");
      } else {
        setStartTimeError(null);
      }
    } else {
      setStartTimeError(null);
    }
  }, [morningStartTime, morningEndTime, morningChecked, eveningChecked]);

  useEffect(() => {
    const startTimeWithinRange =
      dayjs(eveningStartTime).isAfter(
        dayjs()
          .set("hours", 13)
          .set("minutes", 59)
          .set("seconds", 59)
          .set("milliseconds", 59)
      ) &&
      dayjs(eveningStartTime).isBefore(
        dayjs()
          .set("hours", 23)
          .set("minutes", 1)
          .set("seconds", 1)
          .set("milliseconds", 1)
      );

    const endTimeWithinRange =
      dayjs(eveningEndTime).isAfter(
        dayjs()
          .set("hours", 14)
          .set("minutes", 0)
          .set("seconds", 0)
          .set("milliseconds", 0)
      ) &&
      dayjs(eveningEndTime).isBefore(
        dayjs()
          .set("hours", 23)
          .set("minutes", 1)
          .set("seconds", 1)
          .set("milliseconds", 1)
      );

    const endTimeBefore = dayjs(eveningEndTime).isBefore(
      dayjs(eveningStartTime)
    );
    const startTimeAfter = dayjs(eveningStartTime).isAfter(
      dayjs(eveningEndTime)
    );

    if (eveningChecked) {
      if (endTimeWithinRange === false && startTimeWithinRange === true) {
        setStartTimeError("End time should be within the allowed range");
      } else if (
        startTimeWithinRange === false &&
        endTimeWithinRange === true
      ) {
        setStartTimeError("Start time should be within the allowed range");
      } else if (!endTimeWithinRange && !startTimeWithinRange) {
        setStartTimeError(
          "Start time and End time should be within the allowed range"
        );
      } else if (endTimeBefore) {
        setStartTimeError("End time should be greater than start time");
      } else if (startTimeAfter) {
        setStartTimeError("Start time should be lesser than end time");
      } else {
        setStartTimeError(null);
      }
    } else {
      setStartTimeError(null);
    }
  }, [eveningEndTime, eveningStartTime, eveningChecked]);

  const handleSlotDurationChange = (event) => {
    if (editMode) {
      setSlotError("")
      setSelectedSlotDuration(event.target.value);
    }
  };

  const handleMorningSlotsChange = (event) => {
    if (editMode) {
      setMorningSlots(event.target.value);
    }
  };

  const handleEveningSlotsChange = (event) => {
    if (editMode) {
      setEveningSlots(event.target.value);
    }
  };

  const handleRadioChange = (event) => {
    if (editMode) {
      const selectedValue = event.target.value.toUpperCase();
      setSelectedTokenOption(selectedValue);
    }
  };

  const handlePrefixChange = (event) => {
    if (editMode) {
      setPrefixSelection(event.target.value);
      setPrefixError("");
    }
  };

  const handlePrefixValueChange = (event) => {
    if (editMode) {
      setPrefixValue(event.target.value);
      setPrefixError("");
    }
  };

  const generateExampleText = () => {
    if (prefixSelection === "YES" && prefixValue) {
      const exampleNumbers = ["1", "2", "3"]; // You can customize these numbers
      const exampleText = exampleNumbers
        .map((number) => `${prefixValue}${number}`)
        .join(", ");
      return exampleText;
    }
    return "";
  };

  const handleIvrConfigDayChange = (event) => {
    if (editMode) {
      setIvrConfigDay(event.target.value);
    }
  };

  const handleIvrConfigSlotChange = (event) => {
    if (editMode) {
      setIvrConfigSlot(event.target.value);
    }
  };

  const handleEmergencyDurationChange = (event) => {
    if (editMode) {
      setEmergencyDelay(event.target.value);
    }
  };

  const handleEmergencyNotApplicableChange = (event) => {
    if (editMode) {
      setEmergencyNotApplicable(event.target.checked);

      // If "Not Applicable" is checked for Emergency, disable and clear the Emergency Delay Tolerance
      if (event.target.checked) {
        setEmergencyDelay({ hours: 0, minutes: 0 });
      } else {
        setEmergencyDelay(initialData?.EmergencyDelay);
      }
    }
  };

  const handleDurationChange = (event) => {
    if (editMode) {
      setGeneralDelay(event.target.value);
    }
  };

  const handleGeneralNotApplicableChange = (event) => {
    if (editMode) {
      setGeneralNotApplicable(event.target.checked);

      // If "Not Applicable" is checked for General, disable and clear the General Delay Tolerance
      if (event.target.checked) {
        setGeneralDelay({ hours: 0, minutes: 0 });
      } else {
        if (
          initialData &&
          typeof initialData === "object" &&
          "GeneralDelay" in initialData
        ) {
          setGeneralDelay(initialData?.GeneralDelay);
        } else {
          setGeneralDelay({ hours: 0, minutes: 0 });
        }
      }
    }
  };

  const validatePrefix = () => {
    if (prefixSelection === "YES") {
      if (prefixValue.trim().length === 0) {
        setPrefixError("Prefix text is required when Prefix is set to 'Yes'");
      } else if (prefixValue.trim().length > 3) {
        setPrefixError("Prefix text should be 1 to 3 characters");
      } else {
        setPrefixError("");
      }
    } else {
      setPrefixError("");
    }
  };

  const formatTime = ({ hours, minutes }) => {
    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");
    return `${formattedHours}:${formattedMinutes}`;
  };

  const handleUpdateSettings = async () => {
    handleLoaderOpen();
    try {
      if (prefixSelection === "YES" && !prefixValue.trim()) {
        prefixError.push("Prefix value is required");
      }
      let requestBody = {
        availability_data: {
          available_days: selectedDays,
          morning_duration_from: morningStartTime
            ? morningStartTime.format("HH:mm")
            : "",
          morning_duration_to: morningEndTime
            ? morningEndTime.format("HH:mm")
            : "",
          evening_duration_from: eveningStartTime
            ? eveningStartTime.format("HH:mm")
            : "",
          evening_duration_to: eveningEndTime
            ? eveningEndTime.format("HH:mm")
            : "",
          slot_duration: selectedSlotDuration != "0" ? String(selectedSlotDuration) : "",
          morning_slots: String(morningSlots),
          evening_slots: String(eveningSlots),
          type: selectedRadioValue
        },
        ivr_data: {
          no_of_days: ivrConfigDay,
          no_of_slots: ivrConfigSlot,
        },
        token_data: {
          type: selectedTokenOption.toUpperCase(),
          prefix: prefixSelection === "YES" ? prefixValue : "",
        },
        notification_data: {
          emergency_delay_duration: String(EmergencyDelay),
          general_delay_duration: String(GeneralDelay),
        },
      };

      if(selectedRadioValue === "SPECIFIC_SETTING") {
        requestBody.availability_data.specific_setting_from = fromDate?.format("YYYY-MM-DD");
        requestBody.availability_data.specific_setting_to = toDate?.format("YYYY-MM-DD");
      }

      if (selectedRadioValue === "DEFAULT_SETTING") {
        // Only update morning-related values if the morning checkbox is selected
        if (morningChecked) {
          requestBody.availability_data.evening_duration_from = null;
          requestBody.availability_data.evening_duration_to = null;
          requestBody.availability_data.evening_slots = null;
        }

        // Only update evening-related values if the evening checkbox is selected
        if (eveningChecked) {
          requestBody.availability_data.morning_duration_from = null;
          requestBody.availability_data.morning_duration_to = null;
          requestBody.availability_data.morning_slots = null;
        }

        if (morningChecked && eveningChecked) {
          requestBody.availability_data.morning_duration_from = morningStartTime
            ? morningStartTime.format("HH:mm")
            : null;
          requestBody.availability_data.morning_duration_to = morningEndTime
            ? morningEndTime.format("HH:mm")
            : null;
          requestBody.availability_data.evening_slots = String(eveningSlots);
          requestBody.availability_data.evening_duration_from = eveningStartTime
            ? eveningStartTime.format("HH:mm")
            : null;
          requestBody.availability_data.evening_duration_to = eveningEndTime
            ? eveningEndTime.format("HH:mm")
            : null;
          requestBody.availability_data.morning_slots = String(morningSlots);
        }
      }
      console.log(requestBody);

      const res = await Controller.ApiController(
        "PUT",
        `/setting/${appointmentUID}`,
        "",
        requestBody
      );
      handleLoaderClose();
      if (res.type === "success") {
        Alert("success", "Settings updated successfully.");
        setEditMode(false); // Set edit mode to false after successful update
        FetchData(selectedRadioValue,"")
        dispatch(updateSetting( updateSettingValue + 1));
      } else if (res.status === "FAILED") {
        console.log(res.error);
        if(res.error.message === "Mandatory Data Missing"){
          setAvailabilityDaysError("Please check availability days")
        }else if(res.error.message ==="Morning or evening start duration and end duration is mandatory"){
          setDayTimeError("Please select morning either evening")
        }else if(res.error.message ==="Slot duration is mandatory"){
          setSlotError("Slot duration is mandatory")
        }else{          
        Alert("error", res.error.message);
        }
      } else {
        Alert("warning", "Something went wrong");
      }
      // console.log(res);
    } catch (error) {
      handleLoaderClose();
      console.error("Error in handleUpdateSettings:", error);
      setValidationError("An error occurred while updating the settings");
    }
  };

  const handlePostData = async () => {
    try {
      console.log(selectedRadioValue, "selectedRadioValue");
      if (prefixSelection === "YES" && !prefixValue.trim()) {
        prefixError.push("Prefix value is required");
      }
      let requestBody = {
        doctor_uid: Doctor_id,
        availability_data: {
          available_days: selectedDays,
          morning_duration_from: morningStartTime
            ? morningStartTime.$H + ":" + morningStartTime.$m
            : "",
          morning_duration_to: morningEndTime
            ? morningEndTime.$H + ":" + morningEndTime.$m
            : "",
          evening_duration_from: eveningStartTime
            ? eveningStartTime.$H + ":" + eveningStartTime.$m
            : "",
          evening_duration_to: eveningEndTime
            ? eveningEndTime.$H + ":" + eveningEndTime.$m
            : "",
          slot_duration: selectedSlotDuration != "0" ? String(selectedSlotDuration) : "",
          morning_slots: String(morningSlots),
          evening_slots: String(eveningSlots),
          type: selectedRadioValue,
        },

        ivr_data: {
          no_of_days: ivrConfigDay,
          no_of_slots: ivrConfigSlot,
        },
        token_data: {
          type: selectedTokenOption.toUpperCase(),
          prefix: prefixSelection === "YES" ? prefixValue : "",
        },
        notification_data: {
          emergency_delay_duration: String(EmergencyDelay),
          general_delay_duration: String(GeneralDelay),
        },
      };

      if(selectedRadioValue === "SPECIFIC_SETTING") {
        requestBody.availability_data.specific_setting_from = fromDate?.format("YYYY-MM-DD");
        requestBody.availability_data.specific_setting_to = toDate?.format("YYYY-MM-DD");
      }

      console.log(requestBody);
      let res = await Controller.ApiController(
        "POST",
        "/setting",
        "",
        requestBody
      );
      if (res.type === "success") {
        Alert("success", "Setting created successfully");
        setEditMode(false);
        FetchData(selectedRadioValue,"")
        dispatch(updateSetting({ update_setting: updateSettingValue + 1 }));
      } else if (res.status === "FAILED") {
        // 
        if(res.error.message ==="Morning or evening start duration and end duration is mandatory"){
          setDayTimeError("Please select morning either evening")
        } else if(res.error.message ==="Slot duration is mandatory"){
          setSlotError("Slot duration is mandatory")
        }else{
          Alert("error", res.error.message);
        }
        // setErrorMessage(res.error.message)
      } else {
        Alert("warning", "Something went Wrong");
      }
      // console.log(res);
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      setValidationError("An error occurred while submitting the form");
    }
  };

  const handleSubmit = async (event) => {    
    event.preventDefault();
    let validationErrors = [];
    let currentDate = new Date().setHours(0,0,0,0)    
      if(isDayCheckboxSelected && !morningChecked && !eveningChecked){
        validationErrors.push("Please select either Morning or Evening");
        setDayTimeError("Please select either Morning or Evening")
      }
  
      if(morningChecked && !morningStartTime){
        validationErrors.push("Morning start time is required");
      }
  
      if(morningChecked && !morningEndTime){
        validationErrors.push("Morning end time is required");
      }
  
      if(eveningChecked && !eveningStartTime){
        validationErrors.push("Evening start time is required");
      }
  
      if(eveningChecked && !eveningEndTime){
        validationErrors.push("Evening end time is required");
      }
      
      if(selectedRadioValue === "SPECIFIC_SETTING"){  
        if (!fromDate) {
          validationErrors.push("From date is required");
          setFromDateError("From date is required");
        } else if(!(new Date(fromDate.$d) > new Date(currentDate)) && settingDataLength == 0) {
          validationErrors.push("From date is required");
          setFromDateError("Current date is cannot be from date");
        } else if(settingDataLength > 0 && new Date(defaultFromDate.$d) > new Date(fromDate.$d) && !(new Date(defaultFromDate.$d) > new Date(currentDate))){
          validationErrors.push("From dataaae is required");
          setFromDateError("Current date is cannot be from date");
        }

        if (!toDate) {
          validationErrors.push("To date is required");
          setToDateError("To date is required");
        } else if(!(new Date(toDate.$d) >= new Date(fromDate.$d))) {
          validationErrors.push("To date is required");
          setToDateError("Current date is cannot be to date");
        } 

        if(settingDataLength > 0 && toDate && new Date(toDate.$d) >= new Date(fromDate.$d) && new Date(toDate.$d) < new Date(currentDate)){
          setToDateError("Current date is cannot be to date");
          validationErrors.push("To date is required");
        }
        
      } else {
        if(selectedDays.length == 0) {
          validationErrors.push("Please check availability days");
          setAvailabilityDaysError("Please check availability days")
        }
      }
  
      console.log(validationErrors)
      if(validationErrors.length > 0){
        setErrorMessage(validationErrors);
        return;
      }
      setErrorMessage(null);
      setFromDateError(null);
      setToDateError(null);
      setDayTimeError(null)
    

    if (editMode) {
      console.log(settingAvailable,appointmentUID, appointmentAvailable,"appointmentAvailable")
      if (settingAvailable) {
        handleUpdateSettings();
      } else {
        if (startTimeError) {
          Alert("error", startTimeError);
        } else if (endTimeError) {
          Alert("error", endTimeError);      
      }
        handlePostData();      
    }
  }
  };

  const tommorowDate = () =>{
    let tomorrowDate = new Date();
    tomorrowDate.setDate(tomorrowDate.getDate()+1)
    return tomorrowDate;
  }

  return (
    <Grid container spacing={1}>
     <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{display:{xs:"", sm:"none"}}}><Header /></Grid>
     <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{marginTop:{xs:"0px", sm:"0px"}, marginBottom:"25px"}}>
          <BreadCrumbs
            crumbs={["Dashboard", "Settings"]}
            paths={["/dashboard", "/settings"]}
            selected={(crumb) => console.log("hello", crumb)}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <BioDetails /></Grid>
        {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{display:{xs:"", sm:""}}}> */}
        <Box component="form" onSubmit={handleSubmit} mt={3} sx={{ padding: "0px 0px 0px 8.5px"}}>
        <Grid container spacing={1}>
        
        <Grid
            item
            xs={12}
            sm={12}
            md={2}
            lg={2}
            xl={2}
            sx={{ display: "flex", alignItems: "center"}}
          >
            <Box sx={{ display: "flex", alignItems: "center", marginTop: {md:"0px", sm:""}, marginBottom:"10px"}}>
              <h3>Settings</h3>
              <div
                style={{
                  marginLeft: "5px",
                  display: "flex",
                  position: "relative",
                }}
              >
                {editMode ? (
                  <>
                    <Tooltip title="Cancel" disableRipple placement="top" arrow>
                      {/* <img src={cancelIcon}  onClick={handleCancelClick} style={{cursor:"pointer"}}  /> */}
                      <img
                        src={cancelIcon}
                        onClick={() => openPopup("Cancel")}
                        style={{ cursor: "pointer" }}
                      />
                    </Tooltip>

                    <Tooltip title="Save" disableRipple placement="top" arrow>
                      <button
                        type="submit"
                        style={{
                          width: "30px",
                          marginLeft: "20px",
                          padding: "0px",
                          height: "28px",
                          borderRadius: "50px",
                          border: "none",
                          backgroundColor: "#fff",
                        }}
                      >
                        <img
                          src={Save}
                          style={{ marginTop: "2px", cursor: "pointer" }}
                        />
                      </button>
                    </Tooltip>
                  </>
                ) : (
                  <Tooltip
                    title="Edit"
                    disableRipple
                    placement="top"
                    arrow
                    sx={{ marginLeft: "-20px" }}
                  >
                    <img
                      src={editIcon}
                      onClick={()=> selectedRadioValue == "DEFAULT_SETTING" ? FetchData(selectedRadioValue,  "EDIT") : handleEditClick()}
                      // onClick={() => openPopup("Confirmation")}
                      // onClick={handleEditClick}
                      style={{ cursor: "pointer" }}
                    />
                  </Tooltip>
                )}{" "}
              </div>
            </Box>
          </Grid>

          <Grid  item xs={12} sm={12} md={12} lg={10} xl={10} sx={{ display:{md:"flex", xl:"flex", lg:"flex", sm:"", xs:""}, justifyContent:{md:"right", sm:"left", xs:"left"}}}>
              <div style={{marginTop:"-5px",  marginBottom:"5px"}}>
              <FormControl
                sx={{ ".MuiFormControlLabel-label": { fontSize: "14px" },  }}>
                <RadioGroup
                  row
                  aria-labelledby="demo-radio-buttons-group-label"
                  value={selectedRadioValue}
                  name="radio-buttons-group"
                  sx={{display:"flex", justifyContent:"right",
                    flexDirection: { xs: "row", sm: "row", md: "row" },
                    whiteSpace: "nowrap",
                  }}
                  onChange={handleRadioClick}
                >
                  <FormControlLabel
                    labelPlacement="end"
                    value="DEFAULT_SETTING"
                    control={
                      <Radio
                        disabled={editMode && selectedRadioValue == "SPECIFIC_SETTING"}
                        checked={selectedRadioValue == "DEFAULT_SETTING"}                        
                      />
                    }
                    label="Default Settings"
                  />
                  <FormControlLabel
                    labelPlacement="end"
                    value="SPECIFIC_SETTING"
                    control={
                      <Radio
                        disabled={editMode && selectedRadioValue == "DEFAULT_SETTING"}
                        checked={selectedRadioValue == "SPECIFIC_SETTING"}
                      />
                    }
                    label="Specific Settings"
                 />
                </RadioGroup>
              </FormControl>
              </div>


            {selectedRadioValue === "SPECIFIC_SETTING" && (
                <div style={{marginTop:"-30px",}}>
                <FormControl sx={{ marginRight:"5px", marginTop:{sm:"10px", xs:"30px", md:"0px"}}} >
                 <label style={{ fontSize: "14px" }}>From</label>
                  <LocalizationProvider  dateAdapter={AdapterDayjs}>
                      <DemoContainer
                        sx={{ width: "200px", overflow: "hidden" }}
                        components={["DatePicker"]} >
                        <DesktopDatePicker
                          format="DD-MM-YYYY"
                          label={
                            <span style={{ fontSize: "14px" }}>DD-MM-YYYY</span>
                          }
                          value={fromDate}
                          inputProps={{
                            style: {
                              fontSize: "10px"                              
                            },
                            
                          }}
                          onChange={handleFromDateChange}
                          minDate={dayjs(tommorowDate())}
                          disabled={!editMode}
                          sx={{ height: "40px", fontSize: "10px", width: "200px" }}
                          slotProps={{ textField: { size: "small", error: !!fromDateError,}  }}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </FormControl>


                <FormControl sx={{  marginTop:{sm:"10px", xs:"30px", md:"0px"}}}>
                 <label style={{ fontSize: "14px" }}>To</label>
                 <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer
                        sx={{ width: "200px", overflow: "hidden" }}
                        components={["DatePicker"]} >
                        <DesktopDatePicker 
                          format="DD-MM-YYYY"
                          label={
                            <span style={{ fontSize: "14px" }}>DD-MM-YYYY</span>
                          }
                          value={toDate}
                          onChange={handleToDateChange}
                          minDate={dayjs(fromDate || tommorowDate())}
                          disabled={!editMode}
                          inputProps={{
                            style: {
                              fontSize: "10px",
                            },
                          }}
                          sx={{ height: "40px", fontSize: "10px", width: "200px"}}
                          slotProps={{ textField: { size: "small", error: !!toDateError, } }}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
               </FormControl>
               </div>)}
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              {appointmentAvailable == true &&  <p style={{paddingLeft:"0px", marginTop:"0px", marginBottom:"0px", color:"#BC123E"}}>Changes will be effective from tomorrow</p> }
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={7} xl={6.5}>
              <Card
              sx={{
                backgroundColor: "#EEF0FA",
                padding: "18px",
                borderRadius: "10px",
                overflow: "auto",
                height: "100%",
              }} >
              <p
                className="heading"
                style={{ marginTop: "3px", marginBottom: "5px" }}
              >
                Availability
              </p>
              <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <FormGroup
                    className="labelgroup"
                    sx={{
                      fontSize: "14px",
                      display: "flex",
                      marginTop: "5px",
                      flexDirection: {
                        xs: "row",
                        sm: "row",
                        md: "row",
                        lg: "row",
                        xl: "row",
                      },
                      textAlign: "left",
                      marginLeft: "-16px",
                    }}
                  >
                    <FormControlLabel
                      labelPlacement="start"
                      control={
                        <Checkbox sx={{ color: availabilityDaysError  &&  selectedRadioValue == "DEFAULT_SETTING"  ? "#d32f2f" : ""}}
                          value="monday"
                          onChange={handleSelectDayCheckbox}
                          checked={selectedDays.includes("monday")}
                          disabled={selectedRadioValue == "SPECIFIC_SETTING" && !availabilityDays.includes("monday")}
                        />
                      }
                      label={<span style={{ fontSize: "14px" }}>Mon</span>}
                    />
                    <FormControlLabel
                      labelPlacement="start"
                      control={
                        <Checkbox sx={{ color: availabilityDaysError  &&  selectedRadioValue == "DEFAULT_SETTING"  ? "#d32f2f" : ""}}
                          value="tuesday"
                          onChange={handleSelectDayCheckbox}
                          checked={selectedDays.includes("tuesday")}
                          disabled={selectedRadioValue == "SPECIFIC_SETTING" && !availabilityDays.includes("tuesday")}
                        />
                      }
                      label={<span style={{ fontSize: "14px" }}>Tue</span>}
                    />
                    <FormControlLabel
                      labelPlacement="start"
                      control={
                        <Checkbox sx={{ color: availabilityDaysError  &&  selectedRadioValue == "DEFAULT_SETTING" ? "#d32f2f" : ""}}
                          value="wednesday"
                          onChange={handleSelectDayCheckbox}
                          checked={selectedDays.includes("wednesday")}
                          disabled={selectedRadioValue == "SPECIFIC_SETTING" && !availabilityDays.includes("wednesday")}
                        />
                      }
                      label={<span style={{ fontSize: "14px" }}>Wed</span>}
                    />
                    <FormControlLabel
                      labelPlacement="start"
                      control={
                        <Checkbox sx={{ color: availabilityDaysError  &&  selectedRadioValue == "DEFAULT_SETTING" ? "#d32f2f" : ""}}
                          value="thursday"
                          onChange={handleSelectDayCheckbox}
                          checked={selectedDays.includes("thursday")}
                          disabled={selectedRadioValue == "SPECIFIC_SETTING" && !availabilityDays.includes("thursday")}
                        />
                      }
                      label={<span style={{ fontSize: "14px" }}>Thu</span>}
                    />
                    <FormControlLabel
                      labelPlacement="start"
                      control={
                        <Checkbox sx={{ color: availabilityDaysError  &&  selectedRadioValue == "DEFAULT_SETTING" ? "#d32f2f" : ""}}
                          value="friday"
                          onChange={handleSelectDayCheckbox}
                          checked={selectedDays.includes("friday")}
                          disabled={selectedRadioValue == "SPECIFIC_SETTING" && !availabilityDays.includes("friday")}
                        />
                      }
                      label={<span style={{ fontSize: "14px" }}>Fri</span>}
                    />
                    <FormControlLabel
                      labelPlacement="start"
                      control={
                        <Checkbox sx={{ color: availabilityDaysError  &&  selectedRadioValue == "DEFAULT_SETTING" ? "#d32f2f" : ""}}
                          value="saturday"
                          onChange={handleSelectDayCheckbox}
                          checked={selectedDays.includes("saturday")}
                          disabled={selectedRadioValue == "SPECIFIC_SETTING" && !availabilityDays.includes("saturday")}
                        />
                      }
                      label={<span style={{ fontSize: "14px" }}>Sat</span>}
                    />
                    <FormControlLabel
                      labelPlacement="start"
                      control={
                        <Checkbox sx={{ color: availabilityDaysError  && selectedRadioValue == "DEFAULT_SETTING"  ? "#d32f2f" : ""}}
                          value="sunday"
                          onChange={handleSelectDayCheckbox}
                          checked={selectedDays.includes("sunday")}
                          disabled={selectedRadioValue == "SPECIFIC_SETTING" && !availabilityDays.includes("sunday")}
                        />
                      }
                      label={<span style={{ fontSize: "14px" }}>Sun</span>}
                    />
                  </FormGroup>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
  {/* _________________________________________MORNING SECTION______________________________ */}
  <Box sx={{ display: "flex", justifyContent: "left" }}>
                    <Box style={{ marginTop: "7px", marginRight: "5px" }}>
                      <FormControlLabel 
                        value="morning"
                        sx={{ fontStyle: "14px" }}
                        control={
                          <Checkbox 
                            sx={{ fontStyle: "14px" , color: dayTimeError  ? "#d32f2f" : "" }}
                            checked={morningChecked}
                            onChange={handleDurationCheckbox}
                            disabled={(!selectedDays.length == 0 ? false: true )}
                          />
                        }
                        label={
                          <span style={{ fontSize: "14px" }}>Morning</span>
                        }
                        labelPlacement="start"
                        style={{ marginLeft: "0px" }}
                      />
                    </Box>

                    <Box
                      sx={{
                        display: {xs:"", sm:"", md:"flex"},
                        justifyContent: "left",
                        marginLeft:{xs:"10px", md:""}
                      }}
                    >
                      <Typography
                        sx={{
                          marginTop: "18px",
                          marginRight: "15px",
                          fontSize: "14px",
                          
                        }}
                      >
                        Duration
                      </Typography>
                      <Box sx={{display:{md:"flex",xs:"none"}}}>
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        style={{ marginRight: "29px" }}
                      >
                        <DemoContainer
                          sx={{
                            width:{xs:"100px",sm:"140px"},
                            overflow: "hidden",
                            marginRight: "5px",
                          }}
                          components={["TimePicker"]} 
                        >
                          <TimePicker 
                            closeOnSelect={false}
                            readOnly={!morningChecked && !editMode}
                            minTime={dayjs()
                              .set("hours", 8)
                              .set("minutes", 0)
                              .set("second", 0)}
                            maxTime={dayjs()
                              .set("hours", 14)
                              .set("minutes", 0)
                              .set("second", 0)}
                              inputProps={{
                                style: {
                                  fontSize: "14px",
                                },
                              }}
                            sx={{ height: "40px", fontSize: "12px",".MuiInputBase-root":{
                              width:"135px"
                          }}}
                            value={(morningChecked && selectedDays.length !== 0) ? morningStartTime : null}
                            slotProps={{ textField: { size: "small" } }}
                            label={
                              <span style={{ fontSize: "14px" }}>From</span>
                            }
                            onChange={(time) => {
                              setMorningStartTime(time);
                              handleStartTimeChange("morning", time); // Call handleStartTimeChange on change
                            }}
                            disabled={!morningChecked  || !editMode || (!selectedDays.length == 0 ? false: true)}
                            onError={errorMessage}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                     

                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer
                          sx={{ width: "140px", overflow: "hidden" }}
                          components={["TimePicker"]}
                        >
                          <TimePicker
                            closeOnSelect={false}
                            minTime={dayjs().set("hours", 8).set("minutes", 0)}
                            maxTime={dayjs().set("hours", 14).set("minutes", 0)}
                            sx={{ height: "40px", ".MuiInputBase-root":{
                              width:"135px"
                          } }}
                            slotProps={{ textField: { size: "small" } }}
                            label={<span style={{ fontSize: "14px" }}>To</span>}
                            value={(morningChecked && selectedDays.length !== 0) ? morningEndTime : null}
                            onChange={(time) => {
                              setMorningEndTime(time);
                              handleEndTimeChange("morning", time); // Call handleStartTimeChange on change
                            }}
                            disabled={!morningChecked || !editMode || (!selectedDays.length == 0 ? false: true)}
                            onError={errorMessage}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                      </Box>
                    </Box>                    
                  </Box>
                  <Box sx={{display:{md:"none",xs:"flex"}}}>
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        sx={{ marginRight: "29px" }}
                      >
                        <DemoContainer
                          sx={{
                            width: "110px",
                            overflow: "hidden",
                            marginRight: "5px",
                          }}
                          components={["TimePicker"]} 
                        >
                          <TimePicker 
                            closeOnSelect={false}
                            readOnly={!morningChecked && !editMode}
                            minTime={dayjs()
                              .set("hours", 8)
                              .set("minutes", 0)
                              .set("second", 0)}
                            maxTime={dayjs()
                              .set("hours", 14)
                              .set("minutes", 0)
                              .set("second", 0)}
                              inputProps={{
                                style: {
                                  fontSize: "14px",
                                },
                              }}
                            sx={{ height: "40px", fontSize: "12px" ,".MuiInputBase-root":{
                              width:"100px"
                          }}}
                            value={(morningChecked && selectedDays.length !== 0) ? morningStartTime : null}
                            slotProps={{ textField: { size: "small" } }}
                            label={
                              <span style={{ fontSize: "14px" }}>From</span>
                            }
                            onChange={(time) => {
                              setMorningStartTime(time);
                              handleStartTimeChange("morning", time); // Call handleStartTimeChange on change
                            }}
                            disabled={!morningChecked  || !editMode || (!selectedDays.length == 0 ? false: true)}
                            onError={errorMessage}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                     

                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer
                          sx={{ width: "110px", overflow: "hidden" }}
                          components={["TimePicker"]}
                        >
                          <TimePicker
                            closeOnSelect={false}
                            minTime={dayjs().set("hours", 8).set("minutes", 0)}
                            maxTime={dayjs().set("hours", 14).set("minutes", 0)}
                            sx={{ height: "40px", width: "20px",".MuiInputBase-root":{
                              width:"100px"
                          } }}
                            slotProps={{ textField: { size: "small" } }}
                            label={<span style={{ fontSize: "14px" }}>To</span>}
                            value={(morningChecked && selectedDays.length !== 0) ? morningEndTime : null}
                            onChange={(time) => {
                              setMorningEndTime(time);
                              handleEndTimeChange("morning", time); // Call handleStartTimeChange on change
                            }}
                            disabled={!morningChecked || !editMode || (!selectedDays.length == 0 ? false: true)}
                            onError={errorMessage}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                      </Box>
    {/* ________________________________EVENING SECTION_____________________________ */}

            <Box sx={{ display: "flex", justifyContent: "left" }}>
            <Box style={{ marginTop: "7px", marginRight: "5px" }}>
                      <FormControlLabel 
                        value="evening"
                        sx={{ fontStyle: "14px",".MuiInputBase-root":{
                          width:"135px"
                      } }}
                        control={
                          <Checkbox
                            checked={eveningChecked}
                            onChange={handleDurationCheckbox}
                            sx={{ fontStyle: "14px" , color: dayTimeError ? "#d32f2f" : "" }}
                            disabled={(!selectedDays.length == 0 ? false: true)}
                          />
                        }
                        label={
                          <span style={{ fontSize: "14px" }}>Evening</span>
                        }
                        labelPlacement="start"
                        style={{ marginLeft: "0px" }}
                      />
                    </Box>

                    <Box
                      sx={{
                        display: {xs:"", sm:"", md:"flex"},
                        justifyContent: "left",
                        marginLeft:{xs:"10px", md:""}
                      }}
                    >
                      <Typography
                        style={{
                          marginTop: "18px",
                          marginRight: "15px",
                          fontSize: "14px",
                        }}
                      >
                        Duration
                      </Typography>
                      <Box sx={{display:{md:"flex",xs:"none"}}}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer
                          sx={{
                            width:{xs:"100px",sm:"140px"},
                            overflow: "hidden",
                            marginRight: "5px",
                          }}
                          components={["TimePicker"]}
                       >
                          <TimePicker
                          closeOnSelect={false}
                            minTime={dayjs()
                              .set("hours", 14)
                              .set("minutes", 0)
                              .set("seconds", 0)}
                           maxTime={dayjs()
                              .set("hours", 23)
                              .set("minutes", 0)
                              .set("seconds", 0)}
                              inputProps={{
                                style: {
                                  fontSize: "14px",
                                }}}
                            sx={{ height: "40px", ".MuiInputBase-root":{
                              width:"135px"
                          } }}
                            slotProps={{ textField: { size: "small" } }}
                            label={
                              <span style={{ fontSize: "14px" }}>From</span>
                            }
                            value={(eveningChecked && selectedDays.length !== 0) ? eveningStartTime : null}
                            onAccept={(time) =>
                              handleStartTimeChange("evening", time)
                            }
                            onChange={(time) => {
                              setEveningStartTime(time);
                              handleStartTimeChange("evening", time); // Call handleStartTimeChange on change
                            }}
                            disabled={!eveningChecked || !editMode || (!selectedDays.length == 0 ? false: true)}
                            onError={errorMessage}
                          />
                        </DemoContainer>
                      </LocalizationProvider>

                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer
                          sx={{ width: "140px", overflow: "hidden" }}
                          components={["TimePicker"]}
                        >
                          <TimePicker
                          closeOnSelect={false}
                            minTime={dayjs()
                              .set("hours", 14)
                              .set("minutes", 0)
                              .set("seconds", 0)
                              .set("milliseconds", 0)}
                            maxTime={dayjs()
                              .set("hours", 23)
                              .set("minutes", 0)
                              .set("seconds", 0)
                              .set("milliseconds", 0)}
                            sx={{ height: "40px",".MuiInputBase-root":{
                              width:"135px"
                          } }}
                            slotProps={{ textField: { size: "small" } }}
                            label={<span style={{ fontSize: "14px" }}>To</span>}
                            value={(eveningChecked && selectedDays.length !== 0) ? eveningEndTime : null}
                            onChange={(time) => {
                              setEveningEndTime(time);
                              handleEndTimeChange("evening", time); // Call handleStartTimeChange on change
                            }}
                            disabled={!eveningChecked || !editMode}
                            onError={errorMessage}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                      </Box>
                      </Box>

                    {/* {startTimeError && (
                      <div style={{ color: "#d32f2f" }}>{startTimeError}</div>
                    )}
                    {endTimeError && (
                      <div style={{ color: "#d32f2f" }}>{endTimeError}</div>
                    )} */}
                  </Box>
                  <Box sx={{display:{md:"none",xs:"flex"}}}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer
                          sx={{
                            width: "110px",
                            overflow: "hidden",
                            marginRight: "5px",
                          }}
                          components={["TimePicker"]}
                       >
                          <TimePicker
                          closeOnSelect={false}
                            minTime={dayjs()
                              .set("hours", 14)
                              .set("minutes", 0)
                              .set("seconds", 0)}
                           maxTime={dayjs()
                              .set("hours", 23)
                              .set("minutes", 0)
                              .set("seconds", 0)}
                              inputProps={{
                                style: {
                                  fontSize: "14px",
                                }}}
                            sx={{ height: "40px", ".MuiInputBase-root":{
                              width:"100px"
                          } }}
                            slotProps={{ textField: { size: "small" } }}
                            label={
                              <span style={{ fontSize: "14px" }}>From</span>
                            }
                            value={(eveningChecked && selectedDays.length !== 0) ? eveningStartTime : null}
                            onAccept={(time) =>
                              handleStartTimeChange("evening", time)
                            }
                            onChange={(time) => {
                              setEveningStartTime(time);
                              handleStartTimeChange("evening", time); // Call handleStartTimeChange on change
                            }}
                            disabled={!eveningChecked || !editMode || (!selectedDays.length == 0 ? false: true)}
                            onError={errorMessage}
                          />
                        </DemoContainer>
                      </LocalizationProvider>

                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer
                          sx={{ width: "110px", overflow: "hidden" }}
                          components={["TimePicker"]}
                        >
                          <TimePicker
                          closeOnSelect={false}
                            minTime={dayjs()
                              .set("hours", 14)
                              .set("minutes", 0)
                              .set("seconds", 0)
                              .set("milliseconds", 0)}
                            maxTime={dayjs()
                              .set("hours", 23)
                              .set("minutes", 0)
                              .set("seconds", 0)
                              .set("milliseconds", 0)}
                            sx={{ height: "40px", ".MuiInputBase-root":{
                              width:"100px"
                          }}}
                            slotProps={{ textField: { size: "small" } }}
                            label={<span style={{ fontSize: "14px" }}>To</span>}
                            value={(eveningChecked && selectedDays.length !== 0) ? eveningEndTime : null}
                            onChange={(time) => {
                              setEveningEndTime(time);
                              handleEndTimeChange("evening", time); // Call handleStartTimeChange on change
                            }}
                            disabled={!eveningChecked || !editMode}
                            onError={errorMessage}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                      </Box>
                  <Box mt={1}>
                    {startTimeError && (
                      <div style={{ color: "#d32f2f", fontSize: "12px" }}>
                        {startTimeError}
                      </div>
                    )}
                  </Box>
 {/* ______________SLOT SECTION_____________________ */}

 <div style={{ marginTop: "25px", marginLeft: "0px" }}>
                    {/* <h3> Slot Duration </h3> */}
                    <p
                      className="heading"
                      style={{ marginTop: "3px", marginBottom: "5px" }}
                    >
                      Slot Duration
                    </p>
                    <div style={{ display: "flex" }}>
                      <Typography
                        style={{ marginTop: "25px", fontSize: "14px" }}
                      >
                        Slot duration
                      </Typography>
                      <FormControl sx={{ ml: 2, mt: 2, minWidth: 120 }}>
                        <Select
                          value={selectedDays.length !== 0  ? selectedSlotDuration : null}
                          error={slotError}
                          onChange={handleSlotDurationChange}
                          disabled={!editMode || !morningChecked && !eveningChecked}
                          inputProps={{ "aria-label": "Without label" }}
                          size="small"
                          sx={{
                            fontSize: "15px",
                            height: "35px",
                            width: "100px",
                            "&:hover": { border: "1px solid #41BA8F" },
                            "&:hover": {
                              "&& fieldset": { border: "1px solid #41BA8F" },
                            },
                            ".MuiOutlinedInput-notchedOutline": {
                              border: "1px solid #000000",
                            },
                            "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              { border: "1px solid #41BA8F" },
                            "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              { border: "1px solid #41BA8F" },
                          }}
                        >
                          {/* <MenuItem value={"Select Slots"}>
                            <em>Select duration</em>
                          </MenuItem> */}
                          <MenuItem value={10}>00:10</MenuItem>
                          <MenuItem value={15}>00:15</MenuItem>
                          <MenuItem value={20}>00:20</MenuItem>
                          <MenuItem value={30}>00:30</MenuItem>
                        </Select>
                      </FormControl>
                      <Typography
                        style={{
                          marginTop: "25px",
                          marginLeft: "-15px",
                          fontSize: "14px",
                        }}
                      >
                        Mins
                      </Typography>
                    </div>
                  </div>

                  <div
                    style={{
                      marginTop: "15px",
                      marginLeft: "0px",
                      display: "flex",
                    }}
                  >
                    <Typography sx={{ mt: 0.7, fontSize: "14px" }}>
                      Morning slots
                    </Typography>
                    <TextField
                      size="small"
                      id="morningSlot"
                      variant="outlined"
                      value={(morningChecked && selectedDays.length !== 0) ? morningSlots : "0"}
                      disabled={!editMode || !selectedSlotDuration ? true : false}
                    //  onChange={handleMorningSlotsChange}
                      inputProps={{
                        style: {
                          height: "15px",
                          fontSize: "14px",
                        },
                      }}
                      sx={{
                        width: "46px",
                        margin: "0px 0px 0px 10px",
                        height: "15px",
                        paddingBottom: "0px",
                        "&:hover": { border: "1px solid #41BA8F" },
                        "&:hover": {
                          "&& fieldset": { border: "1px solid #41BA8F" },
                        },
                        ".MuiOutlinedInput-notchedOutline": {
                          border: "1px solid #000000",
                        },
                        "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                          { border: "1px solid #41BA8F" },
                        "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                          { border: "1px solid #41BA8F" },
                      }}
                    />
                    <Typography sx={{ mt: 0.7, ml: 2, fontSize: "14px" }}>
                      {" "}
                      Evening slots{" "}
                    </Typography>
                    <TextField
                      size="small"
                      id="eveningSlot"
                      variant="outlined"
                      value={(eveningChecked && selectedDays.length !== 0) ? eveningSlots : "0"}
                      disabled={!editMode || !selectedSlotDuration ? true : false}
                      onChange={handleEveningSlotsChange}
                      inputProps={{
                        style: {
                          height: "15px",
                          fontSize: "14px",
                        },
                      }}
                      sx={{
                        width: "46px",
                        margin: "0px 0px 0px 10px",
                        height: "15px",
                        "&:hover": { border: "1px solid #41BA8F" },
                        "&:hover": {
                          "&& fieldset": { border: "1px solid #41BA8F" },
                        },
                        ".MuiOutlinedInput-notchedOutline": {
                          border: "1px solid #000000",
                        },
                        "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                          { border: "1px solid #41BA8F" },
                        "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                          { border: "1px solid #41BA8F" },
                      }}
                    />
                  </div>
                </Grid>
                
              </Grid>
              </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={5} xl={5.5}>
              <Card
              sx={{
                backgroundColor: "#EEF0FA",
                padding: "18px",
                borderRadius: "10px",
              }}>
 <p
                className="heading"
                style={{ marginBottom: "5px", marginTop: "3px" }}
              >
                Token Generation
              </p>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="female"
                  name="radio-buttons-group"
                  value={selectedTokenOption}
                  onChange={handleRadioChange}
                >
                  <FormControlLabel
                    labelPlacement="end"
                    value="SEQUENTIAL"
                    control={<Radio />}
                    label={
                      <Typography sx={{ fontSize: "14px" }}>
                        {" "}
                        Sequential Number (1, 2, 3…){" "}
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    labelPlacement="end"
                    value="RANDOM"
                    control={<Radio />}
                    label={
                      <Typography sx={{ fontSize: "14px" }}>
                        {" "}
                        Random Number (2 digits){" "}
                      </Typography>
                    }
                  />
                </RadioGroup>
              </FormControl>

              <div style={{ display: "flex", justifyContent: "left" }}>
                <p
                  style={{
                    fontSize: "15px",
                    fontWeight: "600",
                    marginTop: "12px",
                    marginRight: "20px",
                  }}
                >
                  Prefix&nbsp;?
                </p>

                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <Select
                    value={prefixSelection}
                    displayEmpty
                    disabled={!editMode}
                    inputProps={{ "aria-label": "Without label" }}
                    size="small"
                    onChange={handlePrefixChange}
                    onBlur={validatePrefix}
                    sx={{
                      width: "90px",
                      height: "30px",
                      fontSize: "14px",
                      "&:hover": { border: "1px solid #41BA8F" },
                      "&:hover": {
                        "&& fieldset": { border: "1px solid #41BA8F" },
                      },
                      ".MuiOutlinedInput-notchedOutline": {
                        border: "1px solid #000000",
                      },
                      "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                        { border: "1px solid #41BA8F" },
                      "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        { border: "1px solid #41BA8F" },
                    }}
                  >
                    <MenuItem sx={{ fontSize: "14px" }} value={"YES"}>
                      Yes
                    </MenuItem>
                    <MenuItem sx={{ fontSize: "14px" }} value={"NO"}>
                      No
                    </MenuItem>
                  </Select>
                </FormControl>
                <div>
                  <TextField
                    size="small"
                    id="morningSlot"
                    variant="outlined"
                    autoComplete="off"
                    disabled={prefixSelection === "NO"}
                    onChange={handlePrefixValueChange}
                    value={prefixValue}
                    onBlur={validatePrefix}
                    error={Boolean(prefixError)}
                    inputProps={{maxLength: 3, style: { height: "13px", fontSize: "14px" } }}
                    sx={{
                      width: "120px",
                      height: "25px",
                      marginTop: "8px",
                      marginLeft: "-25px",
                      "&:hover": { border: "1px solid #41BA8F" },
                      "&:hover": {
                        "&& fieldset": { border: "1px solid #41BA8F" },
                      },
                      ".MuiOutlinedInput-notchedOutline": {
                        border: "1px solid #000000",
                      },
                      "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                        { border: "1px solid #41BA8F" },
                      "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        { border: "1px solid #41BA8F" },
                    }}
                  />
                  <FormHelperText
                    sx={{ paddingTop: "5px", marginLeft: "-23px" }}
                  >
                    Min. 1 to Max. 3 chars
                  </FormHelperText>
                </div>
              </div>
              {prefixSelection === "YES" && (
                <Typography sx={{ fontSize: "14px" }}>
                  Example: ABC1, ABC2, ABC3
                </Typography>
              )}
            </Card>
            <Card
              sx={{
                backgroundColor: "#EEF0FA",
                padding: "18px",
                borderRadius: "10px",
                marginTop: "5px",
              }}>
            <p className="heading"
                style={{ marginBottom: "5px", marginTop: "3px" }}>
                IVR Configuration
              </p>
              <Typography sx={{ marginTop: "5px", fontSize: "14px" }}>
                Based on the settings here, the IVR system will check the
                availability of open appointments and prompt the caller
                accordingly.
              </Typography>
              <Grid container sx={{ marginTop: "10px" }}>
                <Grid item md={6} lg={6} xl={6} sm={6}>
                  <h5 style={{ fontSize: "15px" }}>No. of days allowed</h5>
                  <Box>
                    <FormControl>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="female"
                        name="radio-buttons-group"
                        onChange={handleIvrConfigDayChange}
                        value={ivrConfigDay}
                      >
                        {/* <FormControlLabel sx={{fontSize:"13px"}} labelPlacement="end" value="Sequential" control={<Radio />} label="Sequential Number (1, 2, 3…)" /> */}
                        {/* <FormControlLabel sx={{fontSize:"13px"}} labelPlacement="end" value="Random" control={<Radio />} label="Random Number (2 digits)" /> */}
                        <FormControlLabel
                          labelPlacement="end"
                          value="1"
                          control={<Radio />}
                          label={
                            <Typography sx={{ fontSize: "14px" }}>
                              1 (only current day)
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          labelPlacement="end"
                          value="2"
                          control={<Radio />}
                          label={
                            <Typography sx={{ fontSize: "14px" }}>
                              2 (current and next day)
                            </Typography>
                          }
                        />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                </Grid>

                <Grid item md={6} lg={6} xl={6} sm={6}>
                  <h5 style={{ fontSize: "15px" }}>No. of slots allowed</h5>
                  <Box>
                    <FormControl>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="female"
                        name="radio-buttons-group"
                        onChange={handleIvrConfigSlotChange}
                        value={ivrConfigSlot}
                      >
                        {/* <FormControlLabel sx={{fontSize:"13px"}} labelPlacement="end" value="Sequential" control={<Radio />} label="Sequential Number (1, 2, 3…)" /> */}
                        {/* <FormControlLabel sx={{fontSize:"13px"}} labelPlacement="end" value="Random" control={<Radio />} label="Random Number (2 digits)" /> */}
                        <FormControlLabel
                          labelPlacement="end"
                          value="1"
                          control={<Radio />}
                          label={
                            <Typography sx={{ fontSize: "14px" }}>
                              1 (open slot)
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          labelPlacement="end"
                          value="2"
                          control={<Radio />}
                          label={
                            <Typography sx={{ fontSize: "14px" }}>
                              2 (open slots)
                            </Typography>
                          }
                        />
                     </RadioGroup>
                    </FormControl>
                  </Box>
                </Grid>
              </Grid>
              </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
              <Card
            sx={{
              backgroundColor: "#EEF0FA",
              padding: "18px",
              borderRadius: "10px",
              height: "100%",
              marginBottom:"20px"
            }} >
<Grid container >
<Grid
                item
                xl={6.5}
                lg={6.5}
                md={6.5}
                xs={12}
                sm={12}
                sx={{ borderRight: {md: "1px solid #C8C7C7", sm:"none", xs:"none"},  }}
              >
                <p
                  className="heading"
                  style={{ marginBottom: "5px", marginTop: "3px" }}
                >
                  Notifications
                </p>
                <Box mt={2}>
                  <h5 style={{ fontSize: "15px", color: "#243665" }}>
                    Cancellation
                  </h5>
                  <Typography
                    sx={{
                      marginTop: "5px",
                      fontSize: "14px",
                    }}
                  >
                    Inform patients about cancellation through SMS or Whatsapp{" "}
                  </Typography>
                </Box>
                <Box mt={2}>
                  <h5
                    style={{
                      fontSize: "15px",
                      color: "#243665",
                      marginTop: height > width ? "20px" :"40px",
                    }}
                  >
                    Delay or Advance Consultation
                  </h5>
                  <Typography
                    sx={{
                      marginTop: "5px",
                      fontSize: "14px",
                    }}
                  >
                    Inform patients about the time adjustment through SMS or
                    Whatsapp{" "}
                  </Typography>
                </Box>
                <Box mt={2}>
                  <h5
                    style={{
                      fontSize: "15px",
                      color: "#243665",
                      marginTop: height > width ? "20px" :"40px",
                    }}
                  >
                    Change of Appointment Slot Notifications
                  </h5>
                  <Typography
                    sx={{
                      marginTop: "5px",
                      fontSize: "14px",
                    }}
                  >
                    Inform patients about the change of appointment slot through
                    SMS or Whatsapp
                  </Typography>
                </Box>
              </Grid>

              <Grid
                item
                xl={5.5}
                lg={5.5}
                md={5.5}
                xs={12}
                sm={12}
                sx={{ padding:{md:"0px 20px", sm:"20px 0px", xs:"20px 0px"} , overflow: "auto" }}
              >
                 <h5 style={{ fontSize: "15px", color: "#243665" }}>
                  Emergency Delay Notification
                </h5>
                <Typography sx={{ mt: 1, fontSize: "14px" }}>
                  When there is a delay due to consultation for emergency
                  patients, system can send a Delay Notification to patients who
                  have not yet arrived. Please set the delay duration beyond
                  which the notification gets triggered.
                </Typography>




                <Box sx={{ display:"flex", width: "" }}>
                  <Typography
                    sx={{
                      whiteSpace: "nowrap",
                      marginTop: "10px ",
                      mr: 2,
                      mt: 2,
                      fontSize: "14px",
                      xs: "none",
                      sm: "none",
                      md: "block",
                    }}
                  >
                    Delay Tolerance
                  </Typography>
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "0",marginTop:{xs:"7px",sm:"0px"} }}
                  >
                    <Select
                      
                      value={GeneralDelay}                     
                      onChange={handleDurationChange}
                      disabled={!editMode}
                      inputProps={{ "aria-label": "Without label" }}
                      size="small"
                      sx={{
                        fontSize: "15px",
                        height: "35px",
                        width: "100px",
                        "&:hover": { border: "1px solid #41BA8F" },
                        "&:hover": {
                          "&& fieldset": { border: "1px solid #41BA8F" },
                        },
                        ".MuiOutlinedInput-notchedOutline": {
                          border: "1px solid #000000",
                        },
                        "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                          { border: "1px solid #41BA8F" },
                        "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                          { border: "1px solid #41BA8F" },
                          
                          
                      }}
                    >
                      {/* <MenuItem value={"Select Slots"}>
                            <em>Select duration</em>
                          </MenuItem> */}
                      <MenuItem value={"00:30"}>00:30</MenuItem>
                      <MenuItem value={"01:00"}>01:00</MenuItem>
                      <MenuItem value={"01:15"}>01:15</MenuItem>
                      <MenuItem value={"01:30"}>01:30</MenuItem>
                      <MenuItem value={"02:00"}>02:00</MenuItem>
                    </Select>
                    <FormHelperText sx={{ fontSize: "14px", marginTop: "5px" }}>
                      &nbsp;HH:MM
                    </FormHelperText>
                  </Box>
                  <Box sx={{display:{xs:"none",sm:"block"}, marginTop: "4px", marginLeft: "8px" }}>
                    <FormGroup>
                      <FormControlLabel
                        style={{ whiteSpace: "nowrap" }}
                        control={
                          <Checkbox
                            checked={GeneralNotApplicable}
                            onChange={handleGeneralNotApplicableChange}
                          />
                        }
                        label={
                          <span style={{ fontSize: "14px" }}>
                            Not Applicable
                          </span>
                        }
                      />
                    </FormGroup>
                  </Box>
                </Box>
                <Box sx={{display:{xs:"block",sm:"none"}, marginTop: "4px", marginLeft: "0px" }}>
                    <FormGroup>
                      <FormControlLabel
                        style={{ whiteSpace: "nowrap" }}
                        control={
                          <Checkbox
                            checked={GeneralNotApplicable}
                            onChange={handleGeneralNotApplicableChange}
                          />
                        }
                        label={
                          <span style={{ fontSize: "14px" }}>
                            Not Applicable
                          </span>
                        }
                      />
                    </FormGroup>
                  </Box>

                <h5
                  style={{
                    fontSize: "15px",
                    color: "#243665",
                    marginTop: "10px",
                  }}
                >
                  General Consultation Delay Notification
                </h5>
                <Typography sx={{ mt: 1, fontSize: "14px" }}>
                  On unpredicted circumstances, there can be a delay in
                  consultation and system can notify the patients who have not
                  yet arrived. Please set the delay duration beyond which the
                  notification gets triggered.
                </Typography>
                <Box sx={{display:"flex", width: "", }}>
                  <Typography
                    sx={{
                      whiteSpace: "nowrap",
                      marginTop: "10px ",
                      mr: 2,
                      mt: 2,
                      fontSize: "14px",
                      xs: "none",
                      sm: "none",
                      md: "block",
                    }}
                  >
                    Delay Tolerance
                  </Typography>
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "0",marginTop:{xs:"7px",sm:"0px"} }}
                  >
                    <Select
                      value={EmergencyDelay}
                      onChange={handleEmergencyDurationChange}
                      disabled={!editMode}
                      inputProps={{ "aria-label": "Without label" }}
                      size="small"
                      sx={{
                        fontSize: "15px",
                        height: "35px",
                        width: "100px",
                        "&:hover": { border: "1px solid #41BA8F" },
                        "&:hover": {
                          "&& fieldset": { border: "1px solid #41BA8F" },
                        },
                        ".MuiOutlinedInput-notchedOutline": {
                          border: "1px solid #000000",
                        },
                        "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                          { border: "1px solid #41BA8F" },
                        "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                          { border: "1px solid #41BA8F" },
                      }}
                    >
                      {/* <MenuItem value={"Select Slots"}>
                            <em>Select duration</em>
                          </MenuItem> */}
                      <MenuItem value={"00:30"}>00:30</MenuItem>
                      <MenuItem value={"01:00"}>01:00</MenuItem>
                      <MenuItem value={"01:15"}>01:15</MenuItem>
                      <MenuItem value={"01:30"}>01:30</MenuItem>
                      <MenuItem value={"02:00"}>02:00</MenuItem>
                    </Select>
                    <FormHelperText sx={{ fontSize: "14px", marginTop: "5px" }}>
                      &nbsp;HH:MM
                    </FormHelperText>
                  </Box>
                  <Box sx={{display:{xs:"none",sm:"block"}, marginTop: "4px", marginLeft: "8px" }}>
                    <FormGroup style={{}}>
                      <FormControlLabel
                        style={{ whiteSpace: "nowrap" }}
                        control={
                          <Checkbox
                            checked={EmergencyNotApplicable}
                            onChange={handleEmergencyNotApplicableChange}
                          />
                        }
                        label={
                          <span style={{ fontSize: "14px" }}>
                            Not Applicable
                          </span>
                        }
                      />
                    </FormGroup>
                  </Box>
                </Box>
                <Box sx={{display:{xs:"block",sm:"none"}, marginTop: "4px", marginLeft: "0px" }}>
                    <FormGroup style={{}}>
                      <FormControlLabel
                        style={{ whiteSpace: "nowrap" }}
                        control={
                          <Checkbox
                            checked={EmergencyNotApplicable}
                            onChange={handleEmergencyNotApplicableChange}
                          />
                        }
                        label={
                          <span style={{ fontSize: "14px" }}>
                            Not Applicable
                          </span>
                        }
                      />
                    </FormGroup>
                  </Box>
 </Grid>
            
</Grid>
          </Card>
              </Grid>
              </Grid>
              </Box>
        {/* </Grid> */}
              <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loaderopen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ConfirmationPop
          Data={popupData}
          yesSubmit={yesSubmit}
          NoSubmit={() => {
            setOpen(false) 
          FetchData(selectedRadioValue, "")
        }}
        />
      </Modal>
              
    </Grid>
  );
}

// Name and Mobile Number validation

const validationRegExp = {
   fullNameReg:new RegExp(/^(?! )[a-zA-Z ]{0,}$/),
   fullNameRegEdit:new RegExp(/^(?! )[a-zA-Z -]{0,}$/),
   mobileNumberReg:new RegExp(/^[\d]{0,10}$/),
   mobileNumberEditReg:new RegExp(/^[\d]{0,11}$/),
   emailPatternReg: new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/),
   passwordReg: new RegExp(/^(?=.*?[0-9])(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[^0-9A-Za-z]).{8,12}$/)
}
 
export default validationRegExp;
import React, { useEffect, useState } from "react";
import '../Forgotpassword/forgotpassword.css';
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import GliderLogo from "../../../Assets/qgliderlogo.png";
// import Doctor from "./../Login/Doctor.png";
import Doctor from "../../../Assets/LoginImage/Login_Qg.png";
import {  FormHelperText, InputAdornment } from "@mui/material";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import UserIcon from '../../../Assets/icons/userIcon.svg';
import MobileIcon from '../../../Assets/icons/Mobileicon.svg';
import { MuiOtpInput } from "mui-one-time-password-input";
import { Link, Navigate, useNavigate } from "react-router-dom";

export default function TermsAndConditions() {


  return (
    <Grid container component="main" sx={{ height: "100vh", padding: "20px" }}>
      <CssBaseline />
      <Grid item xs={12} sm={8} md={6} elevation={6} sx={{ padding: { xl: "0px 120px", xs: "0px", lg: "0px 100px", md: "0px 50px",}, }} >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box>
            <img src={GliderLogo} alt={GliderLogo + ".logo"} width={"155px"}/>
          </Box>

          <h2 style={{ fontWeight: "600", paddingBottom:"25px", display:"flex", justifyContent:"center", marginTop: "40px", }}>PRIVACY POLICY </h2>

          <Box sx={{   maxHeight: { xs: "auto", sm: "66.5vh" },
              overflowY: { xs: "", sm: "auto" },
              overflowX: { xs: "", sm: "hidden" }, }}>
           
           <div >
           <h3 style={{ fontWeight: "600",}}>Privacy Policy </h3>
            <Typography sx={{ fontSize: "16px", fontWeight: "400", marginTop:"10px" }}>
            We appreciate and value the trust that You have placed on us. Our Privacy Policy demonstrates our commitment to the privacy of the users for our App QGlider. Please read the following to learn more about our privacy policy and how we treat personally identifiable information collected from our visitors and users. 
            </Typography >
            <Typography sx={{marginTop:"15px" }}>Please note that by using the QGlider App, You agree to be legally bound by the terms and conditions, privacy policy and terms of services. If You do not agree with these terms, please do not use the products and services offered by QGlider Private Limited.  </Typography>
            </div>

            <div >
           <h3 style={{ fontWeight: "600", marginTop:"20px"}}>Terms of Use  </h3>
            <Typography sx={{ fontSize: "16px", fontWeight: "400", marginTop:"10px" }}>
            QGlider is not an official publication of any hospitals that have tied up with the app. The appointment timings are dynamically estimated and may not match with the actual timings. Appointment slots and availability is actually provided by each of our partner hospitals and QGlider just acts as an interface to these services. The Company is not responsible for any damage caused by the information shown by QGlider. Please cross verify the information.  
            </Typography >
            </div>

            <div >
           <h3 style={{ fontWeight: "600", marginTop:"20px"}}>The Information we collect </h3>
            <Typography sx={{ fontSize: "16px", fontWeight: "400", marginTop:"10px" }}>
            We collect the following information when You use QGlider. Your mobile device's hardware information, Name, E-mail addresses, Phone Number, Banking Information (if You choose to make payment transactions through QGlider), SMS or Message logs, Device ID, Operating System Version Code and Version Name and App Version code and version name. This information will be kept private by QGlider Private Limited and its affiliates and will not be disclosed to third parties other than the service providers we use to help operate our products and services. We collect this information to enable You and others to book appointments and track Your appointment status. However, appointment timing and real time status tracking are best effort services and we do not take responsibility concerning its accuracy. Please cross verify the information.              </Typography >
            </div>

            <div >
           <h3 style={{ fontWeight: "600", marginTop:"20px"}}>Information Security </h3>
            <Typography sx={{ fontSize: "16px", fontWeight: "400", marginTop:"10px" }}>
            We take appropriate security measures to protect against unauthorised access to or unauthorised alteration, disclosure or destruction of data. These include, internal reviews of our data collection, storage and processing practices and security measures including appropriate encryption. The Company makes no guarantees for the uninterrupted and continuous availability of the Product and service or of any specific features of the product or service.                        </Typography >
            </div>

            <div >
           <h3 style={{ fontWeight: "600", marginTop:"20px"}}>Updates to this Privacy Policy </h3>
            <Typography sx={{ fontSize: "16px", fontWeight: "400", marginTop:"10px" }}>
            We state that we will carry out periodical updates to our Privacy Policy at our discretion. By agreeing to use QGlider, You hereby agree to all the terms of our Privacy Policy including any updates, revisions or amendments undertaken by us.  
            </Typography >
            </div>

            <div >
           <h3 style={{ fontWeight: "600", marginTop:"20px"}}>Ownership </h3>
            <Typography sx={{ fontSize: "16px", fontWeight: "400", marginTop:"10px" }}>
            We state that QGlider and all of its background software and technology are owned in its entirety by QGlider Private Limited in all jurisdictions. By using QGlider, You hereby agree that You will not attempt to modify, tinker, recalibrate, reverse engineer or replicate Qglider and its background software and technology in any manner whatsoever. Any attempt by You will result in QGlider Private Limited to take necessary action against you to protect QGlider Private Limited interest.   
            </Typography >
            </div>
           
           
          </Box>
         
        </Box>
      </Grid>
      <Grid
        item
        xs={false}
        sm={4}
        md={6}
        sx={{
          backgroundImage: "url(" + Doctor + ")",
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
          borderRadius: "10px",
        }}
      />
    </Grid>
  );
}

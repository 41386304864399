import React, { useEffect, useState } from "react";
import { Box, Grid, IconButton, InputAdornment, Menu, MenuItem, TextField, Typography } from "@mui/material";

// ---- custom Files ------
import "./biodetails.css";

// ------ custom icons and images ------
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Calender from "../../../Assets/dashboard/calender.svg";
import Hospital from "../../../Assets/dashboard/hospital.svg";
import Doctor from "../../../Assets/dashboard/doctor.svg";
import { useLocation } from "react-router-dom";
import Controller from "../../../Controller/ApiController";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { updateLoggedInData } from "../../Assets/Redux/features/StoreData/userLoginDetails";


export default function BioDetails() {
  const [width, setWidth] = useState("");
  const [height, setHeight] = useState("");
  const [ currentDateTime, setCurrentDateTime ] = useState("");
  const currentPath = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]);
  const [topWidgetData, setTopWidgetData]= useState();
  const [hospitalData, setHospitalData ]= useState({});
  const open = Boolean(anchorEl);
  const storedDate = useSelector( (state) => state.dateFilter.date)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const FullName = localStorage.getItem('full_name') 
  const DoctorName = localStorage.getItem('user_name')
  const HospitalName = localStorage.getItem('hospital_name')

  const dispatch = useDispatch();

  const formDatas = useSelector( (state) => state.userLoginDetails.loggedInDetails)


  useEffect(() =>{
    async function GetTopWidgetData() {
      
    var UID =  localStorage.getItem("DoctorUid")
    var Data = {
      "filter.doctor_uid" : UID,
      "filter.date" : storedDate
    }
    let data = {}
    let response = await Controller.ApiController("GET", `/dashboard/${UID}` )
    if(response.type == "success"){
      setHospitalData(response.data)
 
    }
    // console.log(response, "grgr")
    }
    GetTopWidgetData()
  }, [storedDate])

  useEffect(()=>{
    setWidth(window.innerWidth)
    setHeight(window.innerHeight)
  })

  useEffect(() =>{
    console.log(topWidgetData, "sss")
  }, [topWidgetData, ])

  useEffect(()=>{
    if(storedDate){
      setCurrentDateTime(new Date(storedDate));
    } else {
      setCurrentDateTime(new Date());
    }
    const intervalId = setInterval(()=>{
      if(storedDate){
        setCurrentDateTime(new Date(storedDate));
      } else {
        setCurrentDateTime(new Date());
      }
    }, 1000);
    return () =>{
      clearInterval(intervalId);
    };

  }, [storedDate]);

  const formattedDateTime = new Intl.DateTimeFormat('en-GB', {
    day: 'numeric',   
    month: 'short',
    year: 'numeric',
  
    // hour: 'numeric',
    // minute: 'numeric',
    // hour12: true,
  }).format(currentDateTime);
  // if(height > width){

  // }
  return (
    <>
      {/* <Grid container  sx={{display:{xs:"flex",lg:"flex", md:"flex"}, justifyContent:{lg:"left", md:"left"}}} >
        
          
          
          <Grid  className="flx" item sm={6} xs={12} md={4} lg={2} xl={2} sx={{mt:{xs:"4px", sm:""}}}>
          <img src={Calender} sx={{height:{sm:"30px",xs:"30px"}}} alt={"Calender.logo"} />
           <Typography className="center-image-txt" sx={{fontSize:{sm:"14px",xs:"12px" }, ml:2, }}>{formattedDateTime}</Typography>           
          </Grid>


          <Grid   className="flx" item sm={6} xs={12} md={4} lg={5} xl={5} sx={{mt:{xs:"4px", sm:""}}}>
           <img src={Hospital} sx={{height:{sm:"30px",xs:"30px"}}} alt={"Calender.logo"} />
           <Typography className="center-image-txt"  sx={{fontSize:{sm:"14px",xs:"12px", }, ml:2, }}>{hospitalData.hospital_or_clinic_name  || "--"}</Typography>
          </Grid>

          <Grid   className="flx" item sm={6} xs={12} md={4} lg={5} xl={4} sx={{mt:{xs:"4px", sm:""}}}>
           <img src={Doctor} sx={{height:{sm:"30px",xs:"30px"}}} alt={"Calender.logo"} />
           <Typography className="center-image-txt" sx={{fontSize:{sm:"14px",xs:"12px", }, ml:2,}}>{hospitalData.doctor_name || "--"}</Typography>
           </Grid>


      </Grid> */}

<Box sx={{display:{sm:"flex", xs:""},marginTop:{sm:"", xs:"-10px"}, justifyContent:"left"}} >
        
        <div  className="flx" style={{marginBottom: height > width ? "5px" : "0px",maxWidth:"150px", whiteSpace:"nowrap", marginRight:"20px"}} >
        <img src={Calender} sx={{height:{sm:"30px",xs:"30px"}}} alt={"Calender.logo"} />
         <Typography className="center-image-txt" sx={{fontSize:{sm:"14px",xs:"12px" }, ml:2, }}>{formattedDateTime}</Typography>           
        </div>


        <div   className="flx" style={{marginLeft: height > width ? "0px" : "30px", maxWidth:"350px", marginRight:"20px", marginBottom: height > width ? "5px" : "0px",}}>
         <img src={Hospital} sx={{height:{sm:"30px",xs:"30px"}}} alt={"Calender.logo"} />
         <Typography className="center-image-txt"  sx={{fontSize:{sm:"14px",xs:"12px", }, ml:2, }}>{hospitalData.hospital_or_clinic_name  || "--"}</Typography>
        </div>

        <div   className="flx" style={{marginLeft:height > width ? "0px" : "30px", maxWidth:"350px"}}>
         <img src={Doctor} sx={{height:{sm:"30px",xs:"30px"}}} alt={"Calender.logo"} />
         <Typography className="center-image-txt" sx={{fontSize:{sm:"14px",xs:"12px", }, ml:2,}}>{hospitalData.doctor_name || "--"}</Typography>
         </div>


    </Box>
    </>
  );
}

import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { DialogContent } from '@mui/material';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


export default function Popup({children,close}) {
  const [open, setOpen] = React.useState(true);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClose = (value) => {
    setOpen(close === false ? false : true);
  };

  return (
    <div>
      <Dialog    TransitionComponent={Transition}
        keepMounted  fullScreen={fullScreen} onClose={handleClose} open={open} >
      <DialogContent>
        {children}
        </DialogContent>
        </Dialog>
    </div>
  );
}

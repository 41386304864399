import React, { useEffect, useState } from "react";
import {
  Backdrop,
  Box,
  Button,
  FormControlLabel,
  FormLabel,
  Grid,
  InputAdornment,
  Modal,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import TimeAdjustmentProceed from "./TimeAdjustmentProceed";
import ValueFormat from "../Service/valueFormate";
import Controller from "../Controller/ApiController";
import CustomizedButtons from "../Components/ReuseComponents/Buttons/ColorButton";
import { Alert } from "../Components/ReuseComponents/Alert/Alertmessage";
import Loader from "../Components/ReuseComponents/Loader/loader";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import UtilDateTime from "../Constant/utillDateTime";

export default function TimeAdjustment({ open, onClose }) {
  const Navigate = useNavigate();
  const appointmentData = useSelector((state) => state.appointmentSlice.appointment);
  const manageApiRef = useSelector((state) => state.manageAppointmentSlice.appointment)
  const storedDate = useSelector( (state) => state.dateFilter.date)
  const [timeAdjustType, setTimeAdjustType] = useState("DELAY");
  const [proceedModal, setProceedModal] = useState(false);
  const [conformStatus, setConformStatus] = useState(true);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [minTime, setMinTime] = useState(dayjs(currentDate));
  const [duration, setDuration] = useState({hours:0, minutes:0});
  const [width, setWidth] = useState("");
  const [height, setHeight] = useState("");
  // const [duration, setDuration] = useState("");
  const [durationDis, setDurationDis] = useState("")

  const [time, setTime] = useState({
    fromTime: dayjs(currentDate),
    toTime: dayjs(currentDate),
  });
  const [todayEndTime, setTodayEndTime] = useState(
    dayjs(storedDate).set("hour", 23).set("minutes", 59).set("second", 59)
  );
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [timeAdjustData, setTimeAdjustData] = useState({
    uidData: [],
    delayDuration: 0,
    adjustment: "",
    startFrom: "",
    notArrivedata: 0,
  });
  const [resonMessage, setResonMessage] = useState("");
  const [popupType, setPopupType] = useState("");
  const [loaderOpen, setLoaderOpen] = useState(false);

  const handleProceedCloseModal = () => {
    setProceedModal(false);
  };
  const docterUid = localStorage.getItem("DoctorUid");

  useEffect(() => {
    console.log(manageApiRef,"manageApiRef")
  },[manageApiRef])


  useEffect(() => {
    let currentdate = new Date();
    currentdate = currentdate.setHours(0, 0, 0, 0);
    
    if(new Date(currentdate).toISOString() == storedDate){
      currentdate = new Date().toISOString();
      const timer = setInterval(() => {
        setCurrentDate(new Date());
      }, 30 * 1000);
      return () => {
        clearInterval(timer);
      };
    } else {
      currentdate = storedDate;
      setCurrentDate(new Date(storedDate));
    }
  }, [storedDate]);

  useEffect(()=>{
    setWidth(window.innerWidth)
    setHeight(window.innerHeight)
  })

  // useEffect(() => {
  //   const timer = setInterval(() => {
  //     setCurrentDate(new Date());
  //   }, 10 * 1000);
  //   return () => {
  //     clearInterval(timer);
  //   };
  // }, []);

  useEffect(() => {
    setMinTime(dayjs(currentDate));

    const afterDate = time.fromTime.isAfter(currentDate);
    if (!afterDate) {
      setTime((prev) => {
        return { ...prev, fromTime: dayjs(currentDate) };
      });
    }
  }, [currentDate]);

  // const handleDurationChange = (event) => {
  //   const value = event.target.value;

  //   // Validate input format (HH:MM)
  //   // const regex = /^(\d{0,2}):(\d{0,2})$/;
  //   // if (!regex.test(value)) {
  //   //   // Invalid format, handle accordingly (e.g., show an error message)
  //   //   return;
  //   // }

  //   const [hours, minutes] = value
  //     .split(":")
  //     .map((v) => (v ? parseInt(v, 10) : 0));

  //   const dataHours = Number(hours).toString().padStart(2, "0");
  //   const dataMinutes = Number(minutes).toString().padStart(2, "0");
  //   let validHours;
  //   let validMinutes;
  //   // Ensure that hours and minutes are within the allowed range
  //   if (
  //     dataHours.length > 0 &&
  //     dataHours.length <= 2 &&
  //     dataMinutes.length > 0 &&
  //     dataMinutes.length <= 2
  //   ) {
  //     validHours = Math.min(Math.max(0, dataHours), 99);
  //     validMinutes = Math.min(Math.max(0, dataMinutes), 59);
  //     setDuration({ hours: validHours, minutes: validMinutes });
  //   }
  //   // Update the "To Time" based on the entered duration and "From Time"
  //   if (timeAdjustType === "DELAY") {
  //     if (time.fromTime) {
  //       const toTime = time.fromTime
  //         .add(validHours, "hours")
  //         .add(validMinutes, "minutes");
  //       setTime((prev) => ({ ...prev, toTime }));
  //     }
  //   } else {
  //     if (time.fromTime) {
  //       const toTime = time.fromTime
  //         .subtract(validHours, "hours")
  //         .subtract(validMinutes, "minutes");
  //       setTime((prev) => ({ ...prev, toTime }));
  //     }
  //   }
  // };
  // const handleDurationChange = (event) => {
  //   const input = event.target.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
  //   const hours = input.substring(0, 2);
  //   const minutes = input.substring(2, 4);

  //   setDuration({
  //     hours: Math.min(parseInt(hours) || 0, 99), // Ensure hours are between 0 and 99
  //     minutes: Math.min(parseInt(minutes) || 0, 59), // Ensure minutes are between 0 and 59
  //   });
  // };
  const handleDurationChange = (event) => {
    const input = event.target.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
    let formattedInput = '';
    let hour = 0;
    let minutes = 0;

    if (input.length <= 2) {
      // If less than or equal to 2 characters, use as hours
      hour = input
      formattedInput = hour;
      // setDuration(prev => {return{...prev, hours:formattedInput}})
    } else {
      // If more than 2 characters, format as hours and minutes
      hour = input.substring(0, 2);
      minutes = input.substring(2, 4)
      formattedInput = `${hour}:${minutes}`;
      // setDuration(prev => {return{...prev, hours:input.substring(0, 2), split:":", minutes:input.substring(2, 4)}})

    }

    console.log("formattedInput: ",formattedInput)

    setDurationDis(formattedInput);
    setDuration(prev => {return{...prev,hours:Number(hour), minutes:Number(minutes) }})
    if (timeAdjustType === "DELAY") {
          if (time.fromTime) {
            const toTime = time.fromTime
              .add(Number(hour), "hours")
              .add(Number(minutes), "minutes");
            setTime((prev) => ({ ...prev, toTime }));
          }
        } else {
          if (time.fromTime) {
            const toTime = time.fromTime
              .subtract(Number(hour), "hours")
              .subtract(Number(minutes), "minutes");
            setTime((prev) => ({ ...prev, toTime }));
          }
        }
  };


  // const handleDurationChange = (event) => {
  //   const value = event.target.value;
  //   const [hours, minutes] = value
  //     .split(":")
  //     .map((v) => (v ? parseInt(v, 10) : 0));
  //   setDuration({ hours, minutes });

  //   // Update the "To Time" based on the entered duration and "From Time"
  //   if (timeAdjustType === "DELAY") {
  //     if (time.fromTime) {
  //       const toTime = time.fromTime
  //         .add(hours, "hours")
  //         .add(minutes, "minutes");
  //       setTime((prev) => ({ ...prev, toTime }));
  //     }
  //   } else {
  //     if (time.fromTime) {
  //       const toTime = time.fromTime
  //         .subtract(hours, "hours")
  //         .subtract(minutes, "minutes");
  //       setTime((prev) => ({ ...prev, toTime }));
  //     }
  //   }
  // };

  const handleFromTimeChange = (newValue) => {
    const fromTime = dayjs(newValue);
    let toTime;
    // Update the "To Time" based on the entered duration and new "From Time"
    if (timeAdjustType === "DELAY") {
      toTime = fromTime
        .add(duration.hours, "hours")
        .add(duration.minutes, "minutes");
    } else {
      toTime = fromTime
        .subtract(duration.hours, "hours")
        .subtract(duration.minutes, "minutes");
    }
    setTime({ fromTime, toTime });
  };

  useEffect(() => {
    const fromTime = time.fromTime;
    let toTime;
    // Update the "To Time" based on the entered duration and new "From Time"
    if (timeAdjustType === "DELAY") {
      toTime = fromTime
        .add(duration.hours, "hours")
        .add(duration.minutes, "minutes");
    } else {
      toTime = fromTime
        .subtract(duration.hours, "hours")
        .subtract(duration.minutes, "minutes");
    }
    setTime({ fromTime, toTime });
  }, [timeAdjustType, time.fromTime]);

  useEffect(() => {
    if (
      (duration.hours !== 0 || duration.minutes !== 0) &&
      !dayjs(time.fromTime).isBefore(currentDate)
    ) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [duration.hours, duration.minutes, time.fromTime]);

  useEffect(() => {
    if (timeAdjustData.uidData.length > 0) {
      setConformStatus(false);
    } else {
      setConformStatus(true);
    }
    console.log(timeAdjustData.uidData, "ta-data")
  }, [timeAdjustData.uidData]);

  useEffect(() => {}, []);

  const getTimeDelayAppointment = async () => {
    setLoaderOpen(true);
    let uidDataTemp = [];
    const requestParams = {
      "filter.slot_start_time":  dayjs(storedDate).set('hours',time.fromTime.$H).set('minutes', time.fromTime.$m).set('seconds', 0).set('millisecond', 0).toISOString(),
      "filter.slot_end_time":  todayEndTime,
      "filter.slot_status": "NOT_ARRIVED",
      "filter.doctor_uid": docterUid,
      "filter.paginate": "NO",
      "filter.appointment_date" : storedDate || ""
    };
    const result = await Controller.ApiController(
      "GET",
      "/appointment",
      "?",
      requestParams
    );
    console.log("getTimeAppointment:", result);

    const resultData = ValueFormat.parseAsArray(result.data);
    const queCount = ValueFormat.parseAsInt(result.in_que_count);
    const notArrived = ValueFormat.parseAsInt(result.not_arrived_count);
    const totalRecords = ValueFormat.parseAsInt(result.total_records);
    resultData.map((value) => {
      const appointment_uid = ValueFormat.parseAsString(value.appointment_uid);
      uidDataTemp.push(appointment_uid);
    });
    console.log(result,"uidDataTemp")
    setTimeAdjustData((prev) => {
      return {
        ...prev,
        uidData: uidDataTemp,
        delayDuration: `${duration.hours}:${duration.minutes}`,
        adjustment: timeAdjustType,
        startFrom: time.fromTime,
        notArrivedata: notArrived,
      };
    });
    setLoaderOpen(false);
  };

  const fetchData = () => {
    getTimeDelayAppointment();
  };

  // useEffect(() => {
  //   if (!dayjs(time.toTime).isSame(time.fromTime)) {
  //     getTimeDelayAppointment();
  //   }
  // }, [duration, time.toTime]);

  const postTimeDelay = async () => {
    setLoaderOpen(true);
    const requestParams = {
      not_arrived_appointment_uids: timeAdjustData.uidData,
      delay_duration: timeAdjustData.delayDuration,
      adjustment: timeAdjustData.adjustment,
      start_from: timeAdjustData.startFrom,
      reason: resonMessage,
    };

    console.log("requestParams :", requestParams);
    const postData = await Controller.ApiController(
      "PUT",
      "/appointment_time_adjustment",
      "",
      requestParams
    );
    setLoaderOpen(false);
    const status = postData.type;

    if (status === "success") {
      Alert("success", "Consultation delay updated successfully!");
            closePopUp();
            manageApiRef();
            // appointmentData();
     
    }

    console.log("postData: ", postData);
  };

  const handleProceedOpenModal = (type) => {
    setPopupType(type);
    setProceedModal(true);
  };

  const closePopUp = () => {
    setDurationDis("")
    setDuration((prev) => {
      return { ...prev, hours: 0, minutes: 0 };
    });
    onClose();
  };
  return (
    <>
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        open={open}
        onClose={onClose}
      >
        <Backdrop
          open={open}
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <Paper
            sx={{
              maxWidth: {
                xl: "55%",
                lg: "60%",
                md: "80%",
                sm: "100%",
                xs: "95%",
              },
              borderRadius: "10px",
            }}
          >
            <Loader loaderOpen={loaderOpen} />
            <Grid container sx={{ borderRadius: "10px" }}>
              <Grid
                item
                xs={12}
                sx={{ backgroundColor: "#EEF0FA", borderRadius: "10px" }}
              >
                <Box sx={{ padding:{md:"20px 48px", xs:"20px 18px"}  }}>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: "600",
                      color: "#243665",
                    }}
                  >
                    Consultation Delay - {UtilDateTime.formatMonthDateYear(storedDate) }
                  </Typography>
                </Box>
              </Grid>
              <Grid
                container
                sx={{
                  padding: "10px",
                }}
              >
                <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
                  <Grid container sx={{ padding: {md: "0px 30px", sm:"0px 0px", xs:"0px 0px"}, marginLeft:{xs:"-7.5px", sm:"-7.5px", md:""} }}>
                    <Grid
                      item
                      xl={5}
                      lg={5}
                      md={5}
                      sm={5}
                      xs={9}
                      sx={{
                        borderRight: {
                          xl: "1px solid #000000 !important",
                          lg: "1px solid #000000 !important",
                          md: "1px solid #000000 !important",
                          sm: "1px solid #000000 !important",
                          xs: "none !important",
                        },marginTop:"-6px",
                        paddingRight: "0px",
                      }}
                    >
                        {/* <Grid>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={timeAdjustType}
                            onChange={(E) => setTimeAdjustType(E.target.value)}
                          >
                            <FormControlLabel
                              value="DELAY"
                              control={<Radio />}
                              labelPlacement="start"
                              label="Delay"
                              sx={{ color: "#000000", '& .MuiFormControlLabel-label': { fontSize: "14px" } }}
                            />
                            <FormControlLabel
                              value="ADVANCE"
                              control={<Radio />}
                              label="Advance"
                              labelPlacement="start"
                              sx={{ color: "#000000", '& .MuiFormControlLabel-label': { fontSize: "14px" } }}
                            />
                          </RadioGroup>
                        </Grid> */}
                      <Grid
                        display={"flex"}
                        alignItems={"center"}
                        gap={{sm:6.7, xs:7.2}}
                        paddingLeft={2}
                      >
                        <FormLabel sx={{ color: "#000000",fontSize:"14px" }}>
                          Duration
                        </FormLabel>
                        <Box sx={{ marginTop:{md:"", sm:"15px", xs:""}, ml:{md:"7px", xs:"2.7px"}}}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer
                              components={["TimePicker", "TimePicker"]}
                              sx={{
                                marginTop:{sm:"", xs:"5px"},
                                width: "200px",
                                overflow:"hidden",
                              }}
                            >
                              <TextField
                                sx={{
                                  
                                  color: "#000000",
                                  "& .MuiInputBase-root": {
                                    width: "110px",
                                    overflow:"hidden",// Adjust the width as needed
                                    height:"35px",// Adjust the width as needed
                                    overflowX:"hidden",
                                    overFlowY:"hidden"

                                  },
                                  "& .MuiInputBase-input": {
                                    fontSize: "13px",
                                    paddingLeft: "10px",
                                  },
                                  "& .MuiSvgIcon-root": {
                                    width: "16px", // Adjust icon size if necessary
                                    height: "16px", // Adjust icon size if necessary
                                  },
                                }}
                                type="text"
                                value={durationDis}
                                // value={`${String(duration.hours).padStart(2, "0")}:${String(
                                //   duration.minutes
                                // ).padStart(2, "0")}`}
                                onChange={handleDurationChange}
                                placeholder="00:00"
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end"></InputAdornment>
                                  ),
                                }}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                          <span
                            style={{
                              fontSize: "10px",
                              color: "#999999",
                              paddingLeft: "15px",
                            }}
                          >
                            HH:MM
                          </span>
                        </Box>
                      </Grid>

                      <Grid
                      sx={{ mt:{md:0, sx:2}}}
                       
                        display={"flex"}
                        alignItems={"center"}
                        paddingLeft={2}
                      >
                        <FormLabel
                          sx={{
                            display: {
                              xl: "none",
                              lg: "none",
                              md: "none",
                              sm: "none",
                              xs: "none",
                            },
                          }}
                        >
                          Adjust
                        </FormLabel>
                        <Stack
                          direction={{
                            xl: "column",
                            lg: "column",
                            md: "column",
                            sm: "column",
                            xs: "column",
                          }}
                          spacing={2}
                        >
                          <Grid
                            display={"flex"}
                            alignItems={"center"}
                            gap={3.5}
                          >
                            <FormLabel sx={{ color: "#000000",fontSize:"14px", whiteSpace:{sm:"normal", xs:"nowrap"} }}>
                              Starting&nbsp;From
                            </FormLabel>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer
                                components={["TimePicker", "TimePicker"]}
                                sx={{
                                  width: "200px",
                                  overflow:"hidden"
                                }}
                              >
                                <TimePicker
                                  sx={{
                                    "& .MuiInputBase-root": {
                                      width: "110px",
                                      overflow:"hidden",
                                      height:"35px",// Adjust the width as needed
                                      overflowX:"hidden",
                                      overFlowY:"hidden"                                    
                                 
                                    },
                                    "& .MuiInputBase-input": {
                                      fontSize: "13px",
                                      paddingLeft: "10px",
                                    },
                                    "& .MuiSvgIcon-root": {
                                      width: "16px", // Adjust icon size if necessary
                                      height: "16px", // Adjust icon size if necessary
                                    },
                                  }}
                                  minTime={minTime}
                                  slotProps={{ textField: { size: "small" } }}
                                  value={time.fromTime}
                                  onChange={handleFromTimeChange}
                                />
                              </DemoContainer>
                            </LocalizationProvider>
                          </Grid>
                            {/* <Grid
                              mt={3}
                              display={"flex"}
                              alignItems={"center"}
                              gap={6.66}
                              style={{ marginBottom: 20 }}
                            >
                              <FormLabel sx={{ color: "#000000",fontSize:"14px", whiteSpace:{sm:"normal", xs:"nowrap"} }}>
                                Moved To
                              </FormLabel>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer
                                  components={["TimePicker", "TimePicker"]}
                                  sx={{
                                    width: "200px",
                                    overflow:"hidden"
                                  }}
                                >
                                  <TimePicker
                                    sx={{
                                      "& .MuiInputBase-root": {
                                        width: "110px",
                                        overflow:"hidden",
                                        height:"35px",// Adjust the width as needed
                                        overflowX:"hidden",
                                        overFlowY:"hidden"
                                      },
                                      "& .MuiInputBase-input": {
                                        fontSize: "13px",
                                        paddingLeft: "10px",
                                      },
                                      "& .MuiSvgIcon-root": {
                                        width: "16px", // Adjust icon size if necessary
                                        height: "16px", // Adjust icon size if necessary
                                      },
                                    }}
                                    slotProps={{ textField: { size: "small" } }}
                                    value={time.toTime}
                                    readOnly
                                    components={{
                                      OpenPickerIcon: () => null, // Render an empty component to hide the default icon
                                    }}
                                  />
                                </DemoContainer>
                              </LocalizationProvider>
                            </Grid> */}
                          <Box sx={{marginLeft:{sm:"", xs:"0px !important"}}}>
                            <CustomizedButtons
                              onClick={fetchData}
                              disabled={buttonDisabled}
                              bgColor={"#2C73EB"}
                              color={"white"}
                              hoverColor={"#2C73EB"}
                              text={"Fetch Appointments"}
                              style={{
                                fontSize: "14px",
                                borderRadius: "50px",
                                paddingLeft: 3,
                                paddingRight: 3,
                                marginTop:{xl:"10px", lg:"10px",md:"10px", xs:"0px"}
                              }}
                            />
                          </Box>
                        </Stack>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      xl={7}
                      lg={7}
                      md={7}
                      xs={12}
                      sm={7}
                      sx={{
                        padding: {
                          xl: "0px 0px 0px 30px",
                          lg: "0px 0px 0px 30px",
                          md: "0px 0px 0px 30px",
                          sm: "0px 0px 0px 30px",
                          xs: "0px 0px 0px 20px",
                        },
                      }}
                    >
                      <Box>
                        <Grid sx={{ marginTop: "4%", marginLeft:{xs:"-4px", sm:"-4px", md:""} }}>
                          <Grid
                            sx={{
                              textAlign: {
                                xl: "left",
                                lg: "left",
                                md: "left",
                                sm: "left",
                                xs: "left",
                              },
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: "400",
                                color: "#000",
                              }}
                            >
                              Consultation delay will be notified to{" "}
                              <span
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "600",
                                  color: "#000",
                                }}
                              >
                                {timeAdjustData.notArrivedata} patient(s)
                              </span>  <span style={{display: height > width ? "" : "none", fontSize: "14px", fontWeight: "400",
                                color: "#000",  }}>who have not arrived, through SMS or WhatsApp.</span>
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: "400",
                                color: "#000",
                                marginTop:{sm:"", xs:"5px"},
                                display:{sm:"none", xs:"none", md:"inline", xl:"inline", lg:"inline"}
                              }}
                            >
                              who have not arrived, through SMS or WhatsApp.
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid sx={{ marginTop: "9%", marginLeft:{xs:"-4px", sm:"-4px", md:""} }}>
                          <Grid>
                            <FormLabel
                              sx={{
                                fontSize: "14px",
                                fontWeight: "400",
                                color: "#000",
                              }}
                            >
                              Reason for delay : (optional)
                            </FormLabel>
                            <TextField
                              fullWidth
                              size="xl"
                              sx={{ mt: 0 }}
                              onChange={(e) => setResonMessage(e.target.value)}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                      <Box>
                        <Box
                          display={"flex"}
                          alignItems={"center"}
                          sx={{ marginTop:{md: 2.2,xl: 2.2, lg:2.2, xs:1}, marginLeft:{xs:"10px", sm:"10px", md:""} }}
                        >
                          <Button
                            type="submit"
                            sx={{
                              mt: 3,
                              mb: 2,
                              mr: 2,
                              backgroundColor: "#D13C3C",
                              color: "#FFFFFF",
                              border: "1px solid #FFF",
                              borderRadius: "50px",
                              height: "40px",
                              "&:hover": { background: "#D13C3C" },
                              width: "154px",
                              fontSize: "14px",
                              textTransform: "capitalize",
                            }}
                            onClick={() => handleProceedOpenModal("CANCEL")}

                            // onClick={closePopUp}
                          >
                            Cancel
                          </Button>
                          <CustomizedButtons
                          onClick={() => handleProceedOpenModal("SUBMIT")}
                              disabled={conformStatus}
                              bgColor={"#41BA8F"}
                              color={"white"}
                              hoverColor={"#41BA8F"}
                              text={"Submit"}
                              style={{
                                fontSize: "14px",
                                borderRadius: "50px",
                                paddingLeft: 7,
                                paddingRight: 7,
                                mt:1
                              }}
                            />
                        
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Backdrop>
      </Modal>
      <TimeAdjustmentProceed
        postTimeDelay={postTimeDelay}
        openModalProceed={proceedModal}
        closeProceedModalCancel={handleProceedCloseModal}
        popupType={popupType}
        closePopUp={closePopUp}
      />
    </>
  );
}
import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import AddIcon from '@mui/icons-material/Add';

// -------- custom files --------
import navItems from "../../../Constant/Navmenu";
import { Link } from "react-router-dom";
import DashboardIcon from "../../../Assets/navbar/dashboard.svg";
import Appointment from "../../../Assets/navbar/doctor-appointment.svg";
import Users from "../../../Assets/navbar/Manage_user.svg";
import Setting from "../../../Assets/navbar/Setting.svg";
import CurrentPage from "../../../ActivePage/page";
import DashboardActive from "../../../Assets/mobileNavbar/dashboard_active.svg";
import AppointmentActive from "../../../Assets/mobileNavbar/appointments_active.svg";
import ManageUserActive from "../../../Assets/mobileNavbar/user_active.svg";
import SettingsActive from "../../../Assets/mobileNavbar/settings_active.svg";
import DashboardBlack from "../../../Assets/mobileNavbar/dashboard_black.svg"
import qr_code from"../../../Assets/mobileNavbar/qr_code.svg"
import qr_active from "../../../Assets/mobileNavbar/qr_active.svg"
import NavItemsDashboard from "../../../Constant/NavmenuDashboard";
import NavItems from "../../../Constant/Navmenu";
import MobileNavItems from "../../../Constant/MobileNavMenu";




export default function Menu({ handleBookModalOpen }) {

  const location = CurrentPage();
  // console.log(location, "locationlocation")

  const [showNevbar, setShowNevbar] = useState(["DASHBOARD", "MANAGE_APPOINTMENTS", "MANAGE_USERS", "SETTINGS", "QR_GENERATE" ]);
  
  
  let UserTypeAccess = localStorage.getItem("user_type") || []
 
  let superAdmin = UserTypeAccess.includes("SUPER_ADMIN")
  
  const accessBlockvalidation = () => {
    if(!superAdmin){    
      setShowNevbar(localStorage.getItem("access_permissions")+",QR_GENERATE");       
    }
  }

  useEffect(() => {
    accessBlockvalidation();    
  },[])
  return (
    <div style={{marginBottom:"2px"}}>
      {/* sx={{display:"flex",justifyContent:"center",alignItems:"center",position:"relative",bottom:"25px"}} */}
      {/* <Grid
        container  sx={{display:"flex", justifyContent:"center", position:"relative", marginBottom:"2px",}}>
          <Grid item xs={12} sm={12} sx={{display:"flex", justifyContent:"center"}}>
       <Box onClick={handleBookModalOpen} sx={{display:"flex", marginBottom:"5px",   alignItems:"center",justifyContent:"center",backgroundColor:"#41BA8F",borderRadius:"25px" , width:"35px",height:"35px"}}>
              <AddIcon style={{fill:"white",}}/>
          </Box>
          </Grid>
          </Grid> */}
      <Grid
        container
        sx={{
          border: "1px solid",
          borderRadius: "20px",
          marginLeft: "0px",
          width: "92.5%",
          backgroundColor:"#FFFFFF",
          position: 'fixed',
        }}
        spacing={1}
      >
         {NavItemsDashboard.map((text, index) => (  
        <Grid key={index} item sx={{ textAlign: "center" }} xs={2.4} sm={2.4}>
          {location === "/dashboard" && <a href="dashboard">
            
            {location.includes(text.url) ?  <img src={showNevbar.includes(text.key) ? text.activePage : text.fadeIcon} /> : <img src={showNevbar.includes(text.key) ? text.icon : text.fadeIcon} />}
          </a>}
         {location != "/dashboard" && <Link to={ showNevbar.includes(text.key) && text.url}>
         {location.includes(text.url) ?  <img src={showNevbar.includes(text.key) ? text.activePage : text.fadeIcon} /> : <img src={showNevbar.includes(text.key) ? text.icon : text.fadeIcon} />}
          </Link>}
        </Grid>
         ))}
        {/* <Grid item sx={{ textAlign: "center" }}  xs={2.4} sm={2.4}>
          <Link to="/manageappointments">
            <img src={location === "/manageappointments" ? AppointmentActive : Appointment} ></img>
          </Link>
        </Grid> */}
        
        {/* <Grid item sx={{ textAlign: "center" }}  xs={2.4} sm={2.4}>          
         
          <Link to="/generateQR">
            <img src={location === "/generateQR" ? qr_active : qr_code} ></img>
            </Link>
          
        </Grid> */}

{/* <Grid item sx={{display:"",justifyContent:"center",alignItems:"center",position:"relative",bottom:"25px", marginLeft:"0px"}}  xs={2.4} sm={2.4}>          
          <Box onClick={handleBookModalOpen} sx={{display:"flex",alignItems:"center",justifyContent:"center",backgroundColor:"#41BA8F",borderRadius:"25px" , width:"35px",height:"35px"}}>
              <AddIcon style={{fill:"white"}}/>
          </Box>
             
          <Link to="/generateQR">
            <img src={location === "/generateQR" ? qr_active : qr_code} ></img>
            </Link>
        </Grid> */}
 
 {MobileNavItems.map((text, index) => (
        <Grid key={index} item xs={2.4} sm={2.4}>
          <Link to={ showNevbar.includes(text.key) && text.url}>
           {location.includes(text.url) ?  <img src={showNevbar.includes(text.key) ? text.activePage : text.fadeIcon}></img> :  <img src={showNevbar.includes(text.key) ? text.icon : text.fadeIcon}></img> }
          </Link>
        </Grid>
  ))}
      </Grid>
    </div>
  );
}

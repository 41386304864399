// ------------- React Library -----------

import * as React from "react";
import Box from "@mui/material/Box";
import { useLocation, useNavigate } from "react-router-dom";
// ------------ custom component and Files -----------
import "./App.css"
import LoginRoute from "./Routes/LoginRoute";
import { useEffect, useState } from "react";
import HomePage from "./Components/pages/Home/HomePage";
import "./ActivePage/page"
import { ToastContainer, Zoom } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Controller from "./Controller/ApiController";
import { useDispatch, useSelector } from "react-redux";
import { updateLoggedInData } from "./Components/Assets/Redux/features/StoreData/userLoginDetails";
import { updateAppointmentData } from "./Components/Assets/Redux/features/StoreData/settingAppointmentData";
import { accessValue } from "./Components/Assets/Redux/features/Access/accessPermisson";
// ------------ custom icons and images -------



const drawerWidth = 280;
function App() {

  let dispatch = useDispatch()
  const updateCount = useSelector( (state) => state.updateRequest.update_count)
  const updateSetting = useSelector( (state) => state.updateRequest.update_setting)
  const storedDate = useSelector( (state) => state.dateFilter.date)
  const Location = useLocation();
  const navigate = useNavigate();
  const isLogIn = Location.pathname === "/" || Location.pathname === "/login" || Location.pathname === "/signup" || Location.pathname === "/signupotp" || Location.pathname === "/forgetpassword" || Location.pathname === "/resetpassword" || Location.pathname === "/forgetpassword" || Location.pathname === "/verify_email" || Location.pathname === "/terms&conditions";
  const [ currentDateTime, setCurrentDateTime ] = useState(new Date());
  const currentDay = new Intl.DateTimeFormat('en-US',{ weekday: 'long',}).format(currentDateTime);
  const [availableToday, setAvailableToday] = useState("");
  let Doctor_id = localStorage.getItem('DoctorUid')
  useEffect(()=>{
    if(storedDate){

    }
  },[storedDate])

  useEffect(() =>{
    async function FetchData(){
      let username = localStorage.getItem("user_name")
      // console.log(username, "username");
      try{
        let res = await Controller.ApiController("GET",`/user`, "", {user_name: username});
       
        if (res.type === "success"){
          const loggedInData = {
            // user_name: formData.user_name,
            // password: formData.password,
            full_name: res.data.full_name,
            doctor_name: res.data.doctor_name,
            user_id: res.data.user_uid,
            doctor_uid: res.data.doctor ? res.data.doctor.doctor_uid : res.data.doctor_uid,
            hospital_or_clinic_name: res.data.hospital_or_clinic_name,
            user_type: res.data.user_type,
          };
          dispatch(updateLoggedInData(loggedInData));
          console.log(res.data.access_permissions,"res.data.access_permissions");
          let access = [];
          if(loggedInData.user_type == "SUPER_ADMIN"){
            access = ["DASHBOARD"];
          } else if(res.data.access_permissions) {
            access = res.data.access_permissions;
          }
          dispatch(accessValue(access));
          // console.log(loggedInData, "newvalue");
        }else{
          console.log(res.error);
        }
      }
      catch(error){
        console.log(error);
      }
    }
    // if(!isLogIn) {
      FetchData();
    // }
  }, [updateCount])

  // console.log(isLogIn)

  useEffect(() =>{
  async function consultationCheck(){
    const date = new Date(storedDate)
    const options = { weekday: 'long' };
    let dayString = date.toLocaleDateString('en-US', options);
    try{
      var queryParams = {
        "filter.date" : storedDate
      }
      let res = await Controller.ApiController("GET",`/dashboard/${Doctor_id}`, "", queryParams);

      if (res.type == "success") {
        const getData = res.data;
          dispatch(updateAppointmentData(getData.consultation ? "APPOINTMENT" : "NO_APPOINTMENT"))
      }
    }
    catch(error){
      console.log(error);
    }
  }
  consultationCheck();

},[updateSetting , storedDate, updateCount]);

useEffect(()=>{
  if(!isLogIn && !Location.pathname.includes("/patientqrscan")) {
   let uid = localStorage.getItem("DoctorUid")
   if(uid == null || uid == undefined) {
    navigate("/login")
   }
  }
},[Location])


  return (
    <>
      <div className="AppGlider">
        {isLogIn ? (
          <Box>
            <LoginRoute />
          </Box>
        ) : (<Box>
          <HomePage />
        </Box>
        )}
        <ToastContainer
         position="top-center"
         autoClose={3000} 
         hideProgressBar={true}
         transition={Zoom}
         theme="colored"
         toastStyle={{width:"125%"}}/>
      </div>
    </>
  );
}

export default App;

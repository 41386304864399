// rootReducer.js

import { combineReducers } from 'redux';
// import authReducer from './authSlice';
import  appointmentSlice  from '../Appointment/appointmentReferesh';
import settingSlice from '../Setting/settingSlice';
import userLoginDetails from '../StoreData/userLoginDetails';
import updateRequest from '../updateRequest/updateRequest';
import dateFilter  from '../StoreData/updateDate';
import headerData from "../StoreData/headerData";
import settingAppointmentData from '../StoreData/settingAppointmentData';
import  accessPermissionValue  from '../Access/accessPermisson';
import manageAppointmentSlice from '../Appointment/manageappointmnetRef';


const rootReducer = combineReducers({
  appointmentSlice,
  settingSlice,
  userLoginDetails,
  updateRequest,
  dateFilter,
  headerData,
  settingAppointmentData,
  accessPermissionValue,
  manageAppointmentSlice
  
});

export default rootReducer;

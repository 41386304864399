import {
    Backdrop,
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Grid,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Modal,
    Paper,
    Stack,
    TextField,
    Typography,
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import dayjs from "dayjs";
  import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
  import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
  import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
  import { TimePicker } from "@mui/x-date-pickers/TimePicker";
  import { DatePicker } from "@mui/x-date-pickers/DatePicker";  
  import { MobileDatePicker } from "@mui/x-date-pickers";
//   import Controller from "../../Controller/ApiController";
 
import Loader from "../../ReuseComponents/Loader/loader";
import Controller from "../../../Controller/ApiController";
import { Alert } from "../../ReuseComponents/Alert/Alertmessage";
import ValueFormat from "../../../Service/valueFormate";
import CustomizedButtons from "../../ReuseComponents/Buttons/ColorButton";
import CancelAppointmentChild from "../../../Popup/CancelAppointmentChild";
import UtilDateTime from "../../../Constant/utillDateTime";
import "../../../Assets/css/custom-class.css";
import SubmitAppointmentChildBlock from "./blockAppointmentSubmitPopup";
import "../../../Components/pages/ManageAppointment/blockAppointmentsCss.css";
import { useSelector } from "react-redux";
 
  export default function BlockAppointment({ open, onClose }) {
    const [currentDate, setCurrentDate] = useState(new Date());
    const minDate = dayjs(currentDate); 
    const storedDate = useSelector( (state) => state.dateFilter.date)
    const [value, setValue] = React.useState(dayjs(storedDate));
    const [time, setTime] = useState({
      fromTime: dayjs(currentDate),
      toTime: dayjs(currentDate),
      update: "",
    });
    const minTime = dayjs(currentDate);   
    const [cancelModal, setCancelModal] = useState(false);
    const [submitValidation, setSubmitValidation] = useState(true);
    const [cancelData, setcancelData] = useState({
      queCount: 0,
      notarrivedCount: 0,
      totalCount: 0,
    });
    const [uidData, setUIDData] = useState([]);
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [resonMessage, setResonMessage] = useState("");
    const [loaderOpen, setLoaderOpenn] = useState(false);
    const [popupType, setPopUpType] = useState("");
    const [fromTimeError, setFromTimeError] = useState(false); 
    const docterUid = localStorage.getItem("DoctorUid");
    const handleCancelOpenModal = () => {
      setCancelModal(true);
    };
  
    const handleCancelCloseModal = () => {
      setCancelModal(false);
    };
    useEffect(() => {
      let currentdate = new Date();
      currentdate = currentdate.setHours(0, 0, 0, 0);
      
      if(new Date(currentdate).toISOString() == storedDate){
        currentdate = new Date().toISOString();
        const timer = setInterval(() => {
          setCurrentDate(new Date());
        }, 30 * 1000);
        return () => {
          clearInterval(timer);
        };
      } else {
        currentdate = storedDate;
        setCurrentDate(new Date(storedDate));
      }
    }, [storedDate]);

    useEffect(() => {
      if (!dayjs(time.fromTime).isAfter(currentDate)) {
        setTime((prev) => {
          return { ...prev, fromTime: dayjs(currentDate) };
        });
  
        if (!dayjs(time.toTime).isAfter(time.fromTime)) {
          setTime((prev) => {
            return { ...prev, toTime: dayjs(currentDate) };
          });
        }
      }
    }, [currentDate]);
  
    useEffect(() => {
      if (value !== "") {
        if (
          !time.fromTime.isSame(time.toTime) &&
          time.fromTime !== "" &&
          time.toTime !== "" &&
          !time.fromTime.isBefore(currentDate) &&
          !time.toTime.isBefore(currentDate) &&
          uidData.length > 0
        ) {
          setSubmitValidation(false);
        } else {
          setSubmitValidation(true);
        }
      }
    }, [value, time, currentDate, uidData]);
  
    const getBlockAppointment = async () => {
      setLoaderOpenn(true);
      let uidDataTemp = [];
      const requestParams = {
        "filter.slot_start_time": dayjs(storedDate).set('hours',time.fromTime.$H).set('minutes', time.fromTime.$m).set('seconds', 0).set('millisecond', 0).toISOString(),
        "filter.slot_end_time": dayjs(storedDate).set('hours',time.toTime.$H).set('minutes', time.toTime.$m).set('seconds', 0).set('millisecond', 0).toISOString(),
          "filter.slot_status": "OPEN",
        // "filter.doctor_uid": docterUid,
        "filter.paginate": "NO",
        "filter.current_date" : storedDate || ""
      };
      console.log("requestParams",requestParams,time.fromTime)
      const result = await Controller.ApiController(
        "GET",
        `/get_slot/${docterUid}`,
        "?",
        requestParams
      );
      console.log("getBlockAppointment:", result);
  
      const resultData = ValueFormat.parseAsArray(result.data);
      const queCount = ValueFormat.parseAsInt(result.in_que_count);
      const notArrived = ValueFormat.parseAsInt(result.not_arrived_count);
      const totalRecords = ValueFormat.parseAsInt(result.total_records);
      resultData.map((value) => {
        const slot_uid = ValueFormat.parseAsString(value.slot_uid);
        
        uidDataTemp.push(slot_uid);
      });
      setUIDData(uidDataTemp);
      setcancelData((prev) => {
        return {
          ...prev,
          queCount: queCount,
          notarrivedCount: notArrived,
          totalCount: totalRecords,
        };
      });
      setLoaderOpenn(false);
    };
  
    const submitBlock = async () => {
      const uidDataList = {
        open_slot_uids: uidData,
        reason: resonMessage,
      };
      const result = await Controller.ApiController(
        "PUT",
        "/block_appointment",
        "",
        uidDataList
      );
      const status = result.type;
      if (status === "success") {
        Alert("success", "Appointments are blocked successfully!");
        // setTimeout(() => {
        //   window.location.reload();
        // }, 500);
        getBlockAppointment();
      }
      console.log("result1: ", result);
    };
  
    const fetchData = () => {
      
      let addTwoTime = dayjs(time.fromTime).add(10, 'minutes');      
      if( addTwoTime <= time.toTime){
        getBlockAppointment();
      }else{
        
        setFromTimeError(true)
      }
        
    };
  
    useEffect(() => {
      
      if (
        (time.fromTime.isSame(dayjs(currentDate)) || time.fromTime.isAfter(dayjs(currentDate))) &&
        time.toTime.isAfter(time.fromTime)
      ) {
        setButtonDisabled(false);
      } else setButtonDisabled(true)
    }, [time.fromTime, time.toTime]);
  
    const cancelModel = (type) => {
      setPopUpType(type);
      setCancelModal(true);
    };
  
    const cancelModelclose = () => {
      setTime({
        fromTime: dayjs(currentDate),
        toTime: dayjs(currentDate),
       
      });
      setCancelModal(false);
      onClose();
    };
  
    const cancelModelcloseChild = () => {
      setCancelModal(false);
    };
  
    return (
      <>
        <Modal
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
          open={open}
          onClose={onClose}
        >
          <Backdrop
            open={open}
            sx={{
              zIndex: (theme) => theme.zIndex.drawer + 1,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            }}
          >
            <Paper
              sx={{
                maxWidth: {
                  xl: "45%",
                  lg: "55%",
                  md: "65%",
                  sm: "95%",
                  xs: "95%",
                },
                borderRadius: "10px",
              }}
            >
              <Loader loaderOpen={loaderOpen}/>
  
              <Grid sx={{ borderRadius: "10px" }} container>
                <Grid
                  item
                  xs={12}
                  sx={{ backgroundColor: "#EEF0FA", borderRadius: "10px" }}
                >
                  <Box sx={{ padding: "10px 20px", }}>
                    <Typography
                      sx={{
                        fontSize: "18px",
                        fontWeight: "600",
                        color: "#243665",
                        // textAlign:{lg:"end"},
                        paddingLeft:"0px",
                        paddingRight:{xl:"10px", lg:"62px", md:"55px"}
                      }}
                    >
                      Block Appointments&nbsp;-&nbsp;{UtilDateTime.formatMonthDateYear(storedDate) }
                    </Typography>
                  </Box>
                </Grid>
                <Grid container>
                  <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
                    <Grid container sx={{ padding:{md:"10px 20px", xs:"15px 20px"} ,  }}>
                      <Grid
                        item
                        xl={5}
                        lg={5}
                        md={5}
                        xs={12}
                        sm={12}
                        sx={{
                          borderRight:{md:"1px solid #000000", xs:"none"} ,
                          paddingRight: "10px",
                        }}
                      >
                        <Box>
                        <Box display={{xs:"flex",md:"none"}} alignItems={"center"} gap={2}>
                            <FormLabel
                              sx={{
                                fontSize: "14px",
                                fontWeight: "400",
                                color: "#000",
                                width:{lg:"150px",md:"160px", whiteSpace:"nowrap"}
                              }}
                              className="blockAppointmentsCss"
                            >
                              Appointment For
                            </FormLabel>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer
                                components={["DatePicker", "DatePicker"]}
                                sx={{
                                  width: "300px", overflow:"hidden"
                                }}
                              >
                                <DatePicker
                                  sx={{
                                    "& .MuiInputBase-root": {
                                      width: "110px", // Adjust the width as needed
                                      height: "35px", // Adjust the width as needed
                                      overflow:"hidden"
  
                                    },
                                    "& .MuiInputBase-input": {
                                      fontSize: "13px",
                                      overflow:"hidden"
  
                                    },
                                    "& .MuiSvgIcon-root": {
                                      width: "0px", // Adjust icon size if necessary
                                      height: "0px", // Adjust icon size if necessary
                                    },
                                  }}
                                  readOnly
                                  format="DD/MM/YYYY" 
                                  minDate={minDate}
                                  slotProps={{ textField: { size: "small" } }}
                                  value={value}
                                  onChange={(newValue) => setValue(newValue)}
                                  components={{
                                    OpenPickerIcon: () => null, // Render an empty component to hide the default icon
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      sx={{
                                        "& .MuiInputBase-input": {
                                          fontSize: "13px", // Adjust the font size as needed
                                        },
                                      }}
                                    />
                                  )}
                                />
                              </DemoContainer>
                            </LocalizationProvider>
                            {/* <TextField type='date' value={value} readOnly size='small'/> */}
                          </Box>
                          <Box sx={{ marginTop:{md:"0px", xs:"10px"}}}>
                            <Stack direction={{xs:"row", md:"column"}} spacing={{md:"", xs:-22}}>
                              <Box>
                                  <Box sx={{ display:"flex", alignItems:"center"}} gap={2}>
                                    <Typography
                                      sx={{
                                        fontSize: "13px",
                                        fontWeight: "400",
                                        color: "#000000",
                                        textAlign:"right",
                                        mt: 2,
                                      }}
                                    >
                                      From
                                    </Typography>
                                    <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    <DemoContainer
                                      components={["TimePicker", "TimePicker"]}
                                      sx={{width: "300px", marginTop:"5px"}}
                                    >
                                      <TimePicker
                                        sx={{
                                          "& .MuiInputBase-root": {
                                            width: "110px", // Adjust the width as needed
                                            height: "35px", // Adjust the width as needed
                                            overflow:"hidden"
                                          },
                                          "& .MuiInputBase-input": {
                                            fontSize: "13px",
                                            paddingLeft: "10px",
                                          },
                                          "& .MuiSvgIcon-root": {
                                            width: "16px", // Adjust icon size if necessary
                                            height: "16px", // Adjust icon size if necessary
                                          },
                                          
                                        }}
                                        minTime={minTime}
                                        slotProps={{
                                          textField: { size: "small" },
                                        }}
                                        value={time.fromTime}
                                        onChange={(newValue) =>
                                          setTime((prev) => {
                                            return {
                                              ...prev,
                                              fromTime: newValue,
                                              update: newValue,
                                            };
                                          })
                                        }
                                        
                                      />
                                    </DemoContainer>
                                  </LocalizationProvider>
                                  </Box>
                              </Box>
                              <Box>
                                  <Box sx={{ display:"flex", alignItems:"center"}} gap={{md:4, xs:2}}>
                                    <Typography
                                      sx={{
                                        fontSize: "13px",
                                        fontWeight: "400",
                                        color: "#000000",
                                        textAlign:"right",
                                        mt: 2,
                                      }}
                                    >
                                      To
                                    </Typography>
                                    <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    <DemoContainer
                                      components={["TimePicker", "TimePicker"]}
                                      sx={{
                                        width: "300px", marginTop:"5px"
                                      }}
                                    >
                                      <TimePicker
                                       
                                        sx={{
                                          "& .MuiInputBase-root": {
                                            width: "110px", // Adjust the width as needed
                                            height: "35px", // Adjust the width as needed
                                            overflow:"hidden",
                                            border:fromTimeError ? "1px solid red" : '',
  
                                          },
                                          "& .MuiInputBase-input": {
                                            fontSize: "13px",
                                            paddingLeft: "10px",
                                          },
                                          "& .MuiSvgIcon-root": {
                                            width: "16px", // Adjust icon size if necessary
                                            height: "16px", // Adjust icon size if necessary
                                          },
                                        }}
                                        minTime={minTime}
                                        slotProps={{
                                          textField: { size: "small" },
                                        }}
                                        value={time.toTime}
                                        onOpen={()=> setFromTimeError(false)}
                                        onChange={(newValue) => {
                                          setFromTimeError(false)
                                          setTime((prev) => {
                                            return {
                                              ...prev,
                                              toTime: newValue,
                                              update: newValue,
                                            };
                                          })
                                        }
                                      }
                                      />
                                    </DemoContainer>
                                  </LocalizationProvider>
                              
                                  </Box>
                                  {fromTimeError && <Typography className="errorValidationFromTime">
                          To time should be greater<br/> than from time{window.innerWidth < 900 ? <br/> : ""}&nbsp;(Minimum 10 mins)
                        </Typography>}

                              </Box>
                            </Stack>
                          </Box>
                          <Box mt={4} p={2} pl={{xl:5, lg:1.8, xs:0}}>
                            <CustomizedButtons
                            
                              onClick={fetchData}
                              disabled={buttonDisabled}
                              bgColor={"#2C73EB"}
                              color={"white"}
                              hoverColor={"#2C73EB"}
                              text={"Fetch Open Slots"}
                              style={{
                                fontSize: "14px",
                                borderRadius: "50px",
                                paddingLeft: 3,
                                paddingRight: 3,
                              }}
                            />
                          </Box>
                        </Box>
                      </Grid>
                      <Grid
                        item
                        xl={7}
                        lg={7}
                        md={7}
                        xs={12}
                        sm={12}
                        sx={{ padding:{md:"0px 0px 0px 30px", xs:"0px 0px 0px 5px"}  }}
                      >
                        <Box>
                          <Box display={{xs:"none",md:"flex"}} alignItems={"center"} gap={2}>
                            <FormLabel
                              sx={{
                                fontSize: "14px",
                                fontWeight: "400",
                                color: "#000",
                                width:{lg:"150px",md:"160px", }
                              }}
                              className="blockAppointmentsCss"
                            >
                              Appointment For
                            </FormLabel>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer
                                components={["DatePicker", "DatePicker"]}
                                sx={{
                                  width: "300px",
                                }}
                              >
                                <DatePicker
                                  sx={{
                                    "& .MuiInputBase-root": {
                                      width: "110px", // Adjust the width as needed
                                      height: "35px", // Adjust the width as needed
                                      overflow:"hidden"
  
                                    },
                                    "& .MuiInputBase-input": {
                                      fontSize: "13px",
                                      overflow:"hidden"
  
                                    },
                                    "& .MuiSvgIcon-root": {
                                      width: "0px", // Adjust icon size if necessary
                                      height: "0px", // Adjust icon size if necessary
                                    },
                                  }}
                                  readOnly
                                  format="DD/MM/YYYY" 
                                  minDate={minDate}
                                  slotProps={{ textField: { size: "small" } }}
                                  value={value}
                                  onChange={(newValue) => setValue(newValue)}
                                  components={{
                                    OpenPickerIcon: () => null, // Render an empty component to hide the default icon
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      sx={{
                                        "& .MuiInputBase-input": {
                                          fontSize: "13px", // Adjust the font size as needed
                                        },
                                      }}
                                    />
                                  )}
                                />
                              </DemoContainer>
                            </LocalizationProvider>
                            {/* <TextField type='date' value={value} readOnly size='small'/> */}
                          </Box>
                          <Box sx={{ marginTop: "4%",}}>
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: "400",
                                color: "#000",
                                
                              }}
                            >
                              Total open slots :
                              <span
                                style={{
                                  fontSize: "15px",
                                  fontWeight: "600",
                                  color: "#000",
                                  marginLeft: "3px",
                                  marginTop: "10px",
                                }}
                              >
                                {cancelData.totalCount}
                              </span>
                            </Typography>
                          </Box>
                          <Grid
                            item
                            xl={12}
                            lg={12}
                            md={12}
                            xs={12}
                            sm={12}
                            sx={{ marginTop: "4%" }}
                          >
                            <Grid container>
                              {/* <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
                                <Box sx={{ display: "flex" }}>
                                  <FormControl>
                                    <Button
                                      variant="outlined"
                                      color="warning"
                                      sx={{
                                        fontSize: "13px",
                                        fontWeight: "500",
                                        width: "140px",
                                        height: "40px",
                                        padding: "4px 4px",
                                        textTransform: "none",
                                        cursor: "default",
                                      }}
                                    >
                                      Patients in Que- {cancelData.queCount}
                                    </Button>
                                  </FormControl>
                                  <FormControl style={{ marginLeft: "2%" }}>
                                    <Button
                                      variant="outlined"
                                      color="error"
                                      sx={{
                                        fontSize: "13px",
                                        fontWeight: "500",
                                        width: "160px",
                                        height: "40px",
                                        padding: "4px 4px",
                                        textTransform: "none",
                                        cursor: "default",
                                      }}
                                    >
                                      Patients not Arrived -{" "}
                                      {cancelData.notarrivedCount}
                                    </Button>
                                  </FormControl>
                                </Box>
                              </Grid> */}
                              {/* <Grid item xl={6} lg={6} md={6} xs={6} sm={6}>
                                                          <FormControl >
                                                              <Button variant="outlined" color="error" sx={{ fontSize: "13px", fontWeight: "500",  width: '160px', height:"40px", padding:"4px 4px", textTransform:"none" }}>
                                                                  Patients on Arrived-08
                                                              </Button>
                                                          </FormControl>
                                                      </Grid> */}
                            </Grid>
                          </Grid>
                          <Grid sx={{ marginTop: "4%" }}>
                            <Grid>
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  color: "#000",
                                }}
                              >
                                All open slots for the specified duration will be blocked.
                              </Typography>
                              {/* <Typography
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  color: "#000",
                                  marginTop: "2%",
                                }}
                              >
                                SMS or WhatsApp notification will be sent to{" "}
                                <span
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    color: "#000",
                                  }}
                                >
                                  {cancelData.notarrivedCount} patients.
                                </span>
                              </Typography> */}
                            </Grid>
                          </Grid>
                          <Grid sx={{ marginTop: "2%" }}>
                            <Grid>
                              <FormLabel
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  color: "#000",
                                }}
                              >
                                Reason for blocking : (optional)
                              </FormLabel>
                              <TextField fullWidth
                                // sx={{width:{md:"100%", xs:"50%"}}}
                                onChange={(e) => setResonMessage(e.target.value)}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                        <Box>
                          <Box display={"flex"} alignItems={"center"}>
                            <Button
                              type="submit"
                              sx={{
                                mt: 3,
                                mb: 2,
                                mr: 2,
                                backgroundColor: "#D13C3C",
                                color: "#FFFFFF",
                                border: "1px solid #FFF",
                                borderRadius: "50px",
                                height: "40px",
                                "&:hover": { background: "#D13C3C" },
                                width: "154px",
                                textTransform: "none",
                              }}
                              onClick={() => cancelModel("CANCEL")}
                            >
                              Cancel
                            </Button>
                            {/* <Button
                              disabled={submitValidation}
                              onClick={() => cancelModel("SUBMIT")}
                              type="submit"
                              sx={{
                                mt: 3,
                                mb: 2,
                                backgroundColor:submitValidation === true ?  "rgba(0, 0, 0, 0.5)" : "#41BA8F",
                                color: "#FFFFFF",
                                border: "1px solid #FFF",
                                borderRadius: "50px",
                                height: "40px",
                                "&:hover": { background: "#41BA8F" },
                                width: "154px",
                                textTransform: "none",
                              }}
                            >
                              Submit
                            </Button> */}
  
                            <CustomizedButtons
                              onClick={() => cancelModel("SUBMIT")}
                              disabled={submitValidation}
                              bgColor={"#41BA8F"}
                              color={"white"}
                              hoverColor={"#41BA8F"}
                              text={"Submit"}
                              style={{
                                mt: 3,
                                mb: 2,
                                fontSize: "14px",
                                borderRadius: "50px",
                                width: "154px",
                              }}
                            />
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Backdrop>
        </Modal>
        {cancelModal && (
          <SubmitAppointmentChildBlock
            openModalCancel={cancelModel}
            closeModalCancel={cancelModelclose}
            cancelModelcloseChild={cancelModelcloseChild}
            popupType={popupType}
            submitCancel={submitBlock}
          />
        )}
      </>
    );
  }
  
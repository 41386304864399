import React, { useEffect, useRef, useState } from "react";
import {
  Backdrop,
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  MenuItem,
  Modal,
  Paper,
  TextField,
  Typography,
  Select,
  FormControlLabel,
  RadioGroup,
  Radio,
  setRef,
  Card,
  LinearProgress,
  IconButton,
} from "@mui/material";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "../Assets/css/custom-class.css";
import validationRegExp from "../Configuration/ValidationConfig";
import PropTypes from "prop-types";
import Controller from "../Controller/ApiController";
import UtilDateTime from "../Constant/utillDateTime";
import { useLocation, useNavigate } from "react-router-dom";
import { Alert } from "../Components/ReuseComponents/Alert/Alertmessage";
import '../Popup/bookappointment.css'
import dayjs from "dayjs";
import CancelAppointmentChild from "./CancelAppointmentChild";
import cancellIcon from "../Assets/images/DeletePopup/cancelIcon.svg"
import CircularProgress from "@mui/material/CircularProgress";
import { useSelector } from "react-redux";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "650px !important",
  height: "auto",
  overflow: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 5,
  "@media (min-width: 720px) ": {
    width: "80%",
    // height:"auto",
  },
};

export default function BookAppointment({
  open,
  onClose,
  type,
  data,
  setAppointmentAddUpdate,
  fetchGetAPIData,
  openModalCancel,
  closeModalCancel,
}) {
  // console.log("fetchGetAPIData: ",fetchGetAPIData)

  const [patientNameError, setPatientNameError] = useState("");
  const [selectSlotError, setSelectSlotError] = useState("");
  const [lineProgressLoading, setLineProgressLoading] = useState(true);
  const [openCancelModel, setOpenCancelModel] = useState(false);
  const [radioDisabled, setRadioDisabled] = React.useState(true);
  const [mobileError, setMobileError] = useState("");
  const mobileFieldFocus = useRef(null);
  const nameFieldFocus = useRef(null);
  const mobileTypeFocus = useRef(null);
  const [slotTimeFromTo, setSlotTimeFromTo] = useState([]);
  const [disabledSubmit, setDisabledSubmit] = useState(false);
  var accessPermissonValueGet = useSelector( (state) => state.accessPermissionValue.access_values);  
  const manageApiRef = useSelector((state) => state.manageAppointmentSlice.appointment)

  useEffect(() => {
    console.log(accessPermissonValueGet,"accessPermissonValueGet")
  },[accessPermissonValueGet])

  useEffect(() => {
    console.log(manageApiRef,"manageApiRef")
  },[manageApiRef])

  const Location = useLocation();
  const navigate = useNavigate();
  var initialStateErrors = {
    patientName: { required: false },
    mobileNumber: { required: false },
  };
  
  const [loaderopen, setloaderOpen] = React.useState(false);
  const storedDate = useSelector( (state) => state.dateFilter.date)

  const handleLoaderClose = () => {
    setloaderOpen(false);
  };
  const handleLoaderOpen = () => {
    setloaderOpen(true);
  };

  const [errors, setErrors] = useState(initialStateErrors);
  // var newdate = ;

  const [formData, setFormData] = useState({
    consultation_type: data?.consultation_type
      ? data?.consultation_type
      : "NORMAL",
    appointment_date: storedDate,
    slot_uid: data?.slot.slot_uid ? data?.slot.slot_uid : "default",
    patient_name: data?.patient_name ? data?.patient_name : "",
    mobile_number: data?.mobile_number ? data?.mobile_number : "",
    smart_phone: data?.smart_phone ? data?.smart_phone : true,
    payment_mode: data?.payment_mode ? data?.payment_mode : "",
    booking_source: data?.booking_source ? data?.booking_source : "WEB_API",
    visiting_status: data?.visiting_status ? data?.visiting_status : "NEW",
  });

  const cancelModel = () => {
    setOpenCancelModel(true);
  };

  const cancelModelclose = () => {
    setOpenCancelModel(false);
    onClose();
  };

  const cancelModelcloseChild = () => {
    setOpenCancelModel(false);
  };

  let currentdate = new Date();
  currentdate = currentdate.setHours(0, 0, 0, 0);
  // useEffect(()=>{
  //   if(pathname == "/manageappointments"){
     
  //   }
  // },[])


  const getSlotTime = async () => {
    handleLoaderOpen();
    let docteruid = localStorage.getItem("DoctorUid");
    
    let currentdate = new Date();
    currentdate = currentdate.setHours(0, 0, 0, 0);
    
    if(new Date(currentdate).toISOString() == storedDate){
      currentdate = new Date().toISOString();
    } else {
      currentdate = storedDate;
    }

    let reqParams = {
      "filter.slot_start_time": currentdate, 
      "filter.paginate":"NO",
      "filter.consultation_type":formData.consultation_type,
      "filter.current_date" : storedDate || ""
    };
    // if(formData.consultation_type == "NORMAL") {
    //   reqParams = {...reqParams, "filter.C": "OPEN"}
    // }

    try {
      let res = await Controller.ApiController(
        "GET",
        `/get_slot/${docteruid}`,
        "?",
        reqParams
      );
      handleLoaderClose();
      // console.log(res, "slotTimedata");
      if (res && res.data && Array.isArray(res.data)) {
        // console.log(res.data , "slotidGetdata")
        setLineProgressLoading(false);
        // console.log(res.data, "slotTimedata");

        const slotTimeListdata = res.data.map((item) => ({
          slot_uid: item.slot_uid,
          start_time: item.slot_start_time,
          end_time: item.slot_end_time,
        }));

        // if(formData.consultation_type == "EMERGENCY" && type != "editUser") {
          // setFormData({
          //   ...formData,
          //   slot_uid: slotTimeListdata[0].slot_uid,
          // });
        // }
        setSlotTimeFromTo(slotTimeListdata || []);
      }
    } catch (error) {
      handleLoaderClose();
      console.log(error, "slotTimedata");
    }
  };

  useEffect(() => {
    if(type != "editUser"){
      if(formData.consultation_type == "EMERGENCY"){
        getSlotTime();
        setSelectSlotError("")
      } else {
        setFormData((prev) => ({...prev, slot_uid: data?.slot.slot_uid ? data?.slot.slot_uid : "default"}))
      }
    }
  }, [formData.consultation_type]);

  // Initial state error validation

  useEffect(() => {
    if (formData.patient_name != "" && errors.patientName.required) {
      setErrors({ ...errors, patientName: { required: false } });
    } else if (errors.patientName.required) {
      setErrors({ ...errors, patientName: { required: true } });
    }
  }, [formData.patient_name]);

  useEffect(() => {
    if (formData.mobile_number != "" && errors.mobileNumber.required) {
      setErrors({ ...errors, mobileNumber: { required: false } });
    } else if (errors.mobileNumber.required) {
      setErrors({ ...errors, mobileNumber: { required: true } });
    }
  }, [formData.mobile_number]);

  const clearInputFields = () => {
    formData.consultation_type = "NORMAL";
    formData.appointment_date = "";
    formData.slot_uid = "";
    formData.patient_name = "";
    formData.mobile_number = "";
    formData.smart_phone = true;
    formData.payment_mode = "";
    formData.visiting_status = "";
  };

  const validateFields = () => {
    let patientNameValidate = false;
    let mobileValidate = false;
    let slotValidate = false;

    if (formData.patient_name == "") {
      setPatientNameError(" ");
    } else if (formData.patient_name) {
      let validInput = false;
      if(type == "editUser"){
        validInput = validationRegExp.fullNameRegEdit.test(formData.patient_name);
      }else{
        validInput = validationRegExp.fullNameReg.test(formData.patient_name);
      }      
      if (!validInput) {
        setPatientNameError("Invalid patient name");
      } else {
        setPatientNameError("");
        patientNameValidate = true;
      }
    }

    if (formData.slot_uid == "default") {
      setSelectSlotError(" ");
    } else {
      setSelectSlotError("");
      slotValidate = true;
    }

    if (formData.mobile_number == "") {
      setMobileError(" ");
    } else if (formData.mobile_number) {
      let validationCheck = false;
      if(type == "editUser"){
        validationCheck = validationRegExp.mobileNumberEditReg.test(
          formData.mobile_number
        );
      }else{
        validationCheck = validationRegExp.mobileNumberReg.test(
          formData.mobile_number
        );
      }     
      if (validationCheck) {
        let mobile = "";
        let firstIndex =
          formData.mobile_number[0] == undefined
            ? ""
            : formData.mobile_number[0];
        var repeat = true;
        for (var i = 0; i < formData.mobile_number.length; i++) {
          if (firstIndex != formData.mobile_number[i]) {
            repeat = false;
          }
        }
        for (let i = 0; i < formData.mobile_number.length; i++) {
          mobile +=
            formData.mobile_number[i] != " " ? formData.mobile_number[i] : "";
        }
        if (mobile.length > 9 || mobile == "") {
          if (repeat && mobile != "") {
            setMobileError("Invalid number");
          } else {
            setMobileError("");
            mobileValidate = true;
          }
        } else {
          setMobileError("Mobile number must be 10 digit");
        }
      }
    }
    return patientNameValidate && mobileValidate && slotValidate;
  };

  const appointmentData = useSelector((state) => state.appointmentSlice.appointment);
  // console.log("appointmentReferesh: " , appointmentData);
  useEffect(()=>{
    console.log(appointmentData)
      },[])

  const handleSubmit = async (event) => {
    
    event.preventDefault();
    let focusField = true;

    const validation = validateFields();
    if (!validation) {
      return;
    }
    handleLoaderOpen();
    if (type == "editUser") {     
      let datas = { ...formData };
      if (data?.slot.slot_uid == datas.slot_uid) delete datas.slot_uid;
      // alert(JSON.stringify(datas));
      // alert(data?.appointment_uid);
      let res = await Controller.ApiController(
        "PUT",
        `/appointment/${data?.appointment_uid}`,"",
        datas
      );
      
      if (res) {
        console.log(JSON.stringify(res, "dataListApi"));

        if (res.type === "success") {
          handleLoaderClose();
          setDisabledSubmit(true);
          clearInputFields();
          onClose();
          fetchGetAPIData(); // setAppointmentAddUpdate(prev => alert(prev));
          Alert("success", "Appointment updated successfully");
        } else {
          handleLoaderClose();
          Alert("error", "something went wrong");
        }
      }
    } else {
      handleLoaderOpen();
      let data = { ...formData };
      let res = await Controller.ApiController("POST", "/appointment","",data);
      

      if (res) {
       
        console.log(JSON.stringify(res, "dataListApi"));

        if (res.type === "success") {
          handleLoaderClose();
          setDisabledSubmit(true);        
          Alert("success", "Appointment booked successfully");
          onClose();
          // setTimeout(() => {
            // setRefGetDataListing(refGetDataListing)
                      
            clearInputFields();
            if(accessPermissonValueGet.includes("MANAGE_APPOINTMENTS") && (Location.pathname == "/manageappointments")){
              manageApiRef();                     
            }else if(Location.pathname == "/dashboard"){
              appointmentData();              
            }else{
              navigate(accessPermissonValueGet.includes("DASHBOARD") ? "/dashboard" : "/manageappointments");
            }
           
            // window.location.reload()
          // }, 3500);

          // setAppointmentAddUpdate((current) => current + 1);
        } else {
          handleLoaderClose();
          Alert("error", res.error.message);
        }
        handleLoaderClose();
      }
     
    }
   
  };

  const slotTime = (e) => {
    // if(formData.consultation_type == "NORMAL") {
      setFormData({
        ...formData,
        slot_uid: e.target.value,
      });
    // }
    if (selectSlotError) {
      setSelectSlotError("");
    }
  };

  // Mobile number validation function

  const mobileOnChange = (e) => {
    if (validationRegExp.mobileNumberReg.test(e.target.value)) {
      setFormData({
        ...formData,
        mobile_number: e.target.value,
      });
      if (mobileError) {
        setMobileError("");
      }
    }
  };

  const fullNameOnChange = (e) => {
    var validationCheckName = (type == "editUser" ? validationRegExp.fullNameRegEdit.test(e.target.value) : validationRegExp.fullNameReg.test(e.target.value))
    if (validationCheckName) {
      setFormData({
        ...formData,
        patient_name: e.target.value,
      });
      if (patientNameError) {
        setPatientNameError("");
      }
    }
  };
  

  return (
    <>
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        open={open}
      >
        <Backdrop
          open={open}
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <Paper className="bookmodal"
            sx={{
              width: {xs:"95%", sm:"95%"},
              maxWidth: "650px", // Set the maximum width for larger screens
              margin: "auto",
            }}
          >
            <Grid sx={{ borderRadius: "15px" }} container>
              <Grid
                item
                xs={12}
                sx={{ backgroundColor: "#EEF0FA", borderRadius: "10px" }}
              >
                <Box sx={{ padding:{md:"10px 25px", xs:"10px 10px"} , display:"flex"}}>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: "600",
                      color: "#243665",
                      marginLeft:"0.6px",
                      whiteSpace:"nowrap"
                    }}
                  >
                    {type == "editUser"
                      ? "Edit an Appointment"
                      : "Book an Appointment"}
                  </Typography>
                  <Typography sx={{display:{xs:"block",sm:"none"},fontSize: "18px",fontWeight: "600",  color: "#243665", marginLeft:"0.6px", whiteSpace:"nowrap" }}> &nbsp;-&nbsp;{UtilDateTime.formatYearMonthAndDayAsInt(storedDate) } </Typography>
                  <Typography sx={{display:{xs:"none",sm:"block"},fontSize: "18px",fontWeight: "600",  color: "#243665", marginLeft:"0.6px", whiteSpace:"nowrap" }}> &nbsp;-&nbsp;{UtilDateTime.formatMonthDateYear(storedDate) } </Typography>
                  {/* <Typography sx={{display:{xs:"block",sm:"none"},fontSize: "18px",fontWeight: "600",  color: "#243665", marginLeft:"0.6px", whiteSpace:"nowrap" }}>{UtilDateTime.formatMonthDateYear(storedDate)}</Typography> */}
                </Box>
              </Grid>
              <hr />
              <Grid item md={12} xs={12} sm={12} sx={{ padding: "0% 5% 5%" }}>
                <Box component="form" onSubmit={handleSubmit}>
                  <Grid container spacing={2}>
                    <FormControl
                      sx={{ marginLeft: "10px", marginBottom: "17px" }}
                    >
                      <Box display={"flex"}>
                        <Box sx={{ mt: "9px" }}>
                          {" "}
                          <FormLabel
                            sx={{
                              color: "#000000",
                              "&.Mui-focused": { color: "#000000" },
                              fontSize:"14px",
                              whiteSpace:"nowrap"
                            }}
                            lab
                            id="demo-radio-buttons-group-label"
                          >
                            Consultation Type
                          </FormLabel>{" "}
                        </Box>
                        <Box sx={{marginLeft:"35px",}} className="groupField">
                          <RadioGroup
                            row
                            aria-labelledby="demo-radio-buttons-group-label"
                            value={
                              formData.consultation_type
                                ? formData.consultation_type
                                : ""
                            }
                            onChange={(e) => {
                              let consultationType = e.target.value;

                              setFormData({
                                ...formData,
                                consultation_type: consultationType,
                              });
                            }}
                            name="consultation_type"

                            sx={{
                              "& .MuiFormControlLabel-label": {
                                fontSize: "14px",
                              },
                            }}
                            
                          >
                            <FormControlLabel
                              value="NORMAL"
                              control={<Radio />}
                              label="Normal"
                              className="radioGroupFont"
                            />
                            <FormControlLabel value="EMERGENCY"
                            disabled={type == "editUser" || storedDate != new Date(currentdate).toISOString() ? true : false}
                              control={<Radio />}
                              label="Emergency / VIP"
                            /> 
                          </RadioGroup>
                        </Box>
                      </Box>
                    </FormControl>

                    <FormControl
                      fullWidth
                      sx={{ marginLeft: "10px", marginBottom: "17px" }}
                    >
                      <Box display={"flex"} alignItems={"center"}>
                        <Box sx={{marginBottom:"18px"}}>
                          <FormLabel
                            htmlFor="availableSlot"
                            sx={{
                              fontSize: "14px",
                              fontWeight: 400,
                              color: selectSlotError ? "#d32f2f" : "#000000",
                              // backgroundColor:"red"
                            }}
                          >
                            Select Open Slot*
                          </FormLabel>
                        </Box>
                        {/* <Box sx={{ margin:{md:"0px 0px 0px 38px", xs:"0px 0px 0px 38px"}  }} className="groupField"> */}
                          {/* <TextField id="availableSlot" variant="outlined" size="small" sx={{ width: '50%' }}/> */}
                          <FormControl>
                            <Select className="groupField"
                              size="small"
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              sx={{
                                width: {
                                  xs: "40vw",
                                  sm: "20vw",
                                  md: "16vw",
                                  lg: "13vw",
                                  xl: "13vw",
                                  xxl: "13vw",
                                },
                                backgroundColor:
                                  type == "editUser" ? "#454545" : "#1A71FF",
                                color: "#FFFFFF",
                                "& .MuiSelect-icon": {
                                  color: "#FFFFFF",
                                },
                                height: "40px",
                                fontSize: "14px",
                                marginLeft:"35px",
                              }}
                              value={formData.slot_uid}
                              onChange={slotTime}
                              onOpen={getSlotTime}
                            >
                              {type === "editUser" ? (
                                <MenuItem sx={{fontSize:"14px"}} value={formData.slot_uid}>
                                  {UtilDateTime.formatTimeAsHHMMTT(
                                    data?.slot.slot_start_time
                                  ) +
                                    "-" +
                                    UtilDateTime.formatTimeAsHHMMTT(
                                      data?.slot.slot_end_time
                                    )}
                                </MenuItem>
                              ) : (
                                <MenuItem sx={{fontSize:"14px"}} value="default" disabled>
                                  Select slot
                                </MenuItem>
                              )}

                              {slotTimeFromTo.map((item, i) => (
                                <MenuItem sx={{fontSize:"14px"}} key={i} value={item.slot_uid}>
                                  { UtilDateTime.formatTimeAsHHMMTT(
                                    item.start_time)
                                 +
                                    "-" +
                                    UtilDateTime.formatTimeAsHHMMTT(
                                      item.end_time
                                    )}
                                </MenuItem>
                              ))}
                              {lineProgressLoading && (
                                <Box sx={{ width: "100%" }}>
                                  <LinearProgress />
                                </Box>
                              )}
                            </Select>
                            <Typography
                              sx={{
                                lineHeight: "0px",
                                color: "red",
                                marginTop: "15px",
                                fontSize: "14px",
                              }}
                            >
                              {selectSlotError}
                            </Typography>
                          </FormControl>
                        {/* </Box> */}
                      </Box>
                    </FormControl>

                    <FormControl
                      fullWidth
                      sx={{ marginLeft: "10px", marginBottom: "31px" }}
                    >
                      <Box
                        display={"flex"}
                        // justifyContent={"space-between"}
                        alignItems={"center"}
                      >
                        <FormLabel
                          htmlFor="patientName"
                          sx={{
                            fontSize: "14px",
                            fontWeight: "400",
                            color: patientNameError ? "#d32f2f" : "#000000",
                            whiteSpace:"nowrap",
                          }}
                        >
                          Patient Name*
                        </FormLabel>
                        <TextField
                          id="patient_name"
                          className="patientname"
                          placeholder="Enter patient name"
                          name="patient_name"
                          variant="outlined"
                          size="small"
                          error={patientNameError ? patientNameError : ""}
                          sx={{
                            width:{sm: "75%", xs: "60%"},
                            height: "40px",
                            marginLeft:"55px",
                            fontSize: "14px !important",
                            "& .MuiOutlinedInput-root": {
                              fontSize:"14px",
                              // '&:hover fieldset': {
                              //   borderColor: 'green', // border color on hover
                              // },
                              "&.Mui-focused fieldset": {
                                borderColor: "#C8C7C7", // border color on focus
                              },
                            },
                          }}
                          ref={nameFieldFocus}
                          value={
                            formData.patient_name ? formData.patient_name : ""
                          }
                          onChange={(e) => fullNameOnChange(e)}
                        />

                        <Typography className="errorValidationTextMobile">
                          {patientNameError}
                        </Typography>
                      </Box>
                    </FormControl>

                    <FormControl
                      fullWidth
                      sx={{ marginLeft: "10px", marginBottom: "20px" }}
                    >
                      <Box
                        display={"flex"}
                        // justifyContent={"space-between"}
                        alignItems={"center"}
                      >
                        <FormLabel
                          htmlFor="patientName"
                          sx={{
                            fontSize: "14px",
                            fontWeight: "400",
                            color: mobileError ? "#d32f2f" : "#000000",
                            whiteSpace:"nowrap",
                          }}
                        >
                          Mobile Number*
                        </FormLabel>
                        <TextField
                          id="patientName"
                          className="mobilenumber"
                          variant="outlined"
                          placeholder="Enter mobile number"
                          size="small"
                          name="mobile_number"
                          error={mobileError ? mobileError :  ""}
                          sx={{
                            width:{sm:"75%", xs:"60%"} ,
                            marginLeft:"45px",
                            height: "40px",
                            fontSize: "14px",
                            "& .MuiOutlinedInput-root": {
                              fontSize:"14px",
                              "&.Mui-focused fieldset": {
                                borderColor: "#C8C7C7", // border color on focus
                              },
                            },
                          }}
                          ref={mobileFieldFocus}
                          value={
                            formData.mobile_number ? formData.mobile_number : ""
                          }
                          onChange={(e) => mobileOnChange(e)}
                        />
                        {
                          <Typography className="errorValidationTextMobilePopupBook">
                            {mobileError}
                          </Typography>
                        }
                      </Box>
                    </FormControl>

                    {/* <FormControl
                      fullWidth
                      sx={{ marginLeft: "10px", marginBottom: "10px" }}
                    >
                      <Box display={"flex"} alignItems={"center"}>
                        <FormLabel
                          htmlFor="patientName"
                          sx={{
                            fontSize: "14px",
                            fontWeight: "400",
                            color: "#000000",
                            "&.Mui-focused": { color: "#000000" },
                          }}
                        >
                          Smartphone
                        </FormLabel>
                        <Box sx={{ margin:{md:"0px 0px 0px 68px", xs:"0px 0px 0px 74px"}  }} className="groupField">
                          <RadioGroup
                            row
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="Normal"
                            name="radio-buttons-group"
                            ref={mobileTypeFocus}
                            value={formData.smart_phone ? "YES" : "NO"}
                            onChange={(e) => {
                              let smartPhone = e.target.value;
                              setFormData({
                                ...formData,
                                smart_phone: smartPhone == "YES",
                              });
                            }}
                            sx={{
                              "& .MuiFormControlLabel-label": {
                                fontSize: "14px",
                              },
                            }}
                          >
                            <FormControlLabel
                              value="YES"
                              control={<Radio />}
                              label="Yes"
                            />
                            <FormControlLabel
                              value="NO"
                              control={<Radio />}
                              label="No"
                            />
                          </RadioGroup>
                        </Box>
                      </Box>
                    </FormControl> */}

                    {/* <FormControl
                      fullWidth
                      sx={{ marginLeft: "10px", marginBottom: "30px" }}
                    >
                      <Box
                        display={"flex"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                      >
                        <FormLabel
                          htmlFor="patientName"
                          sx={{
                            fontSize: "14px",
                            fontWeight: "400",
                            color: "#000000",
                            "&.Mui-focused": { color: "#000000" },
                          }}
                        >
                          Payment Mode
                        </FormLabel>
                        <Select
                          id="payment_mode"
                          className="paymentmode"
                          variant="outlined"
                          size="small"
                          name="payment_mode"
                          sx={{
                            width:{sm:"75%", xs:"60%"} ,
                            height: "40px",
                            fontSize: "14px",
                            "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#C8C7C7",
                              },
                          }}
                          displayEmpty
                          inputProps={{
                            "aria-label": "Select Mode of payment",
                          }}
                          value={formData.payment_mode}
                          onChange={(e) => {
                            let paymentMode = e.target.value;
                            // alert(paymentMode)
                            setFormData({
                              ...formData,
                              payment_mode: paymentMode,
                            });
                          }}
                        >
                          <MenuItem sx={{fontSize:"14px"}} value="" disabled>
                            Select Mode of payment
                          </MenuItem>
                          <MenuItem sx={{fontSize:"14px"}} value="CASH">Cash Payment</MenuItem>
                          <MenuItem sx={{fontSize:"14px"}} value="UPI">UPI Payment</MenuItem>
                          <MenuItem sx={{fontSize:"14px"}} value="CARD">
                            Debit/Credit Card Payment
                          </MenuItem>
                        </Select>
                      </Box>
                    </FormControl> */}

                    {/* <FormControl
                      fullWidth
                      sx={{ marginLeft: "10px", marginBottom: "5px" }}
                    >
                      <Box
                        display={"flex"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                      >
                        <FormLabel
                          htmlFor="patientName"
                          sx={{
                            fontSize: "14px",
                            fontWeight: "400",
                            color: "#000000",
                          }}
                        >
                          Visitor Category
                        </FormLabel>
                        <TextField
                          variant="outlined"
                          name="visiting_status"
                          id="visiting_status"
                          className="visitingstatus"
                          size="small"
                          sx={{
                            width:{sm:"75%", xs:"60%"},
                            height: "40px",
                            fontSize: "14px",
                            backgroundColor: "#CCE0FF",
                            "& .MuiOutlinedInput-root": {
                              fontSize:"14px",
                              "&.Mui-focused fieldset": {
                                borderColor: "#C8C7C7", 
                              },
                            },
                          }}
                          value={
                            formData.visiting_status
                              ? formData.visiting_status
                              : ""
                          } */}
                          {/* // onChange={(e) => {
                          //   let visitingStatus = e.target.value;
                          //   setFormData({
                          //     ...formData,
                          //     visiting_status: visitingStatus,
                          //   });
                          // }}
                        /> */}
                        {/* <Box sx={{margin:"0px 0px 0px 45px"}}>
                                            <RadioGroup
                                              row
                                              aria-labelledby="demo-radio-buttons-group-label"
                                              defaultValue="Normal"
                                              name="radio-buttons-group"
                                              value={visitingStatus}
                                              onChange={(e) => setVisitingStatus(e.target.value)}
                                              sx={{
                                                
                                                '& .MuiFormControlLabel-label': {
                                                  fontSize: '14px', 
                                                 
                                                },
                                               
                                              }}
                                              
                                            >
                                              <FormControlLabel  value="Normal" control={<Radio />} label="New" />
                                              <FormControlLabel value="Emergency" control={<Radio />} label="Existing" />
                                            </RadioGroup>
                                        </Box> */}
                    {/* //   </Box>
                    // </FormControl> */}
                  </Grid>

                  <Box>
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      sx={{ marginLeft:{sm:"-7px", xs:"-7px"}  }}
                    >
                      <Button
                        sx={{
                          mt: 3,
                          mb: 2,
                          mr: 2,
                          backgroundColor: "#D13C3C",
                          color: "#FFFFFF",
                          border: "1px solid #FFF",
                          borderRadius: "50px",
                          height: "40px",
                          "&:hover": { background: "#D13C3C" },
                          width: "154px",
                        }}
                        onClick={cancelModel}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        disabled={disabledSubmit}
                        sx={{
                          mt: 3,
                          mb: 2,
                          backgroundColor: "#41BA8F",
                          color: "#FFFFFF",
                          border: "1px solid #FFF",
                          borderRadius: "50px",
                          height: "40px",
                          "&:hover": { background: "#41BA8F" },
                          width: "154px",
                        }}
                      >
                        {type == "editUser" ? "Submit" : "Confirm"}
                      </Button>
                    </Box>
                  </Box>

                  <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loaderopen}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Backdrop>
       
      </Modal>
      {openCancelModel && ( <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        open={cancelModel}
      >
        <Backdrop
          open={cancelModel}
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <Paper sx={{ width: "350px", borderRadius: "10px" }}>
           
            <Grid sx={{ borderRadius: "15px" }} container>
              <Grid
                item
                xs={12}
                sx={{ backgroundColor: "#EEF0FA", borderRadius: "10px" }}
              >
                <Box
                  sx={{
                    height: "70px",
                    backgroundColor: "#FFD7D7",
                    borderRadius: "10px 10px 0px 0px",
                  }}
                >
                  <IconButton
                    sx={{
                      marginTop: "35px",
                      marginLeft: "142px",
                      backgroundColor: "#D13C3C",
                      height: "54px",
                      width: "55px",
                      "&:hover": { backgroundColor: "#D13C3C" },
                      cursor:"default"
                    }}
                  >
                    <img src={cancellIcon} alt="deleteicon" />
                  </IconButton>
                </Box>
              </Grid>
              <hr />
              <Grid item md={12} xs={12} sm={12} sx={{ padding: "0% 5% 5%" }}>
                <Box>
                  <Grid container>
                    <Grid item xs={12} sm={12}>
                      <Typography
                        mt={2}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          fontWeight: 600,
                          fontSize: "16px",
                        }}
                      >
                        Are you sure you want
                      </Typography>
                      <Typography
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          fontWeight: 600,
                          fontSize: "16px",
                        }}
                      >
                        to cancel the activity ?
                      </Typography>
                    </Grid>
                  </Grid>

                  <Box>
                    <Box
                      sx={{ display: "flex", justifyContent: "center" }}
                      mt={2}
                    >
                      <Button
                        sx={{
                          mr: 2,
                          backgroundColor: "#000000",
                          color: "#FFFFFF",
                          height: "25px",
                          "&:hover": { background: "#000000" },
                          width: "95px",
                          fontSize: "13px",
                          fontWeight: 600,
                          borderRadius: "30px",
                          textTransform: "none",
                        }}
                        onClick={cancelModelcloseChild}
                      >
                        No
                      </Button>
                      <Button
                        sx={{
                          backgroundColor: "#41BA8F",
                          color: "#FFFFFF",
                          height: "25px",
                          "&:hover": { background: "#41BA8F" },
                          width: "95px",
                          fontSize: "13px",
                          fontWeight: 600,
                          borderRadius: "30px",
                          textTransform: "none",
                        }}
                        onClick={cancelModelclose}
                      >
                        Yes
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid> 
           
          </Paper>
        </Backdrop>
      </Modal> )}
      {/* {openCancelModel && (
        <CancelAppointmentChild
          openModalCancel={cancelModel}
          closeModalCancel={cancelModelclose}
          cancelModelcloseChild={cancelModelcloseChild}
        />
      )} */}
    </>
  );
}
